import { format, sub } from 'date-fns';

import {
  CandidateAutoupdatedFields,
  CandidateStatusFormFields,
  CandidateStatusFormValues,
} from '@types';
import {
  AcceptedAnotherOfferStatuses,
  AllClosedStatuses,
  CandidateStatus,
  CandidateStatusStage,
  ClosingStatusesByStage,
  ClosingStatusesWithoutFields,
  DisqualifiedStatuses,
  LeadClosedBecauseByCandidateStatus,
  NoSuitableOpeningsStatuses,
  NonStageRelatedStatuses,
} from './candidateStatus';
import {
  ClientInterviewStatus,
  SeniorityToYearsOfExperience,
  TechnicalInterviewStatus,
  TestTaskStatus,
  VideoInterviewStatus,
} from '@constants';
import { LocationDetailsByCountry } from './location';
import { checkIfHasActiveIntroCall } from '@utils/candidateStatusForm';

const StageFailedAggregatedStatueses: Record<
  CandidateStatusStage,
  {
    formFieldsNames: Array<keyof CandidateStatusFormFields> | null;
    autoupdatedFields: Partial<CandidateAutoupdatedFields> | null;
  }
> = {
  [CandidateStatusStage.Application]: {
    formFieldsNames: null,
    autoupdatedFields: null,
  },
  [CandidateStatusStage.VideoInterview]: {
    formFieldsNames: ['VI_feedback'],
    autoupdatedFields: {
      VI_result: [
        VideoInterviewStatus.Sent,
        VideoInterviewStatus.Done,
        VideoInterviewStatus.Failed,
      ],
    },
  },
  [CandidateStatusStage.TestTask]: {
    formFieldsNames: null,
    autoupdatedFields: null,
  },
  [CandidateStatusStage.IC]: {
    formFieldsNames: ['Recruiters_Feedback', 'Prescreen_failed_because'],
    autoupdatedFields: null,
  },
  [CandidateStatusStage.TI]: {
    formFieldsNames: ['Technical_Conslusion'],
    autoupdatedFields: {
      Interview: [
        TechnicalInterviewStatus.Scheduled,
        TechnicalInterviewStatus.Interviewed,
        TechnicalInterviewStatus.Failed,
      ],
    },
  },
  [CandidateStatusStage.TLIntro]: {
    formFieldsNames: ['TL_intro_feedback'],
    autoupdatedFields: {
      Interview: [
        TechnicalInterviewStatus.TLScheduled,
        TechnicalInterviewStatus.TLInterviewed,
        TechnicalInterviewStatus.TLFailed,
      ],
    },
  },
  [CandidateStatusStage.Submission]: {
    formFieldsNames: [
      "Client's_Feedback",
      'Final_decision_from_client',
      'Date_of_Submission_Reply',
    ],
    autoupdatedFields: null,
  },
  [CandidateStatusStage.CI]: {
    formFieldsNames: [
      "Client's_Feedback",
      'Final_decision_from_client',
      'Date_of_Submission_Reply',
    ],
    autoupdatedFields: {
      Clients_Interview: [
        ClientInterviewStatus.Interviewed,
        ClientInterviewStatus.Failed,
      ],
    },
  },
  [CandidateStatusStage.SOW]: {
    formFieldsNames: null,
    autoupdatedFields: null,
  },
  [CandidateStatusStage.Hiring]: {
    formFieldsNames: null,
    autoupdatedFields: null,
  },
};

export const CandidateStatusFormConfig: Partial<
  Record<
    CandidateStatus,
    (
      stage: CandidateStatusStage,
      formValues: CandidateStatusFormValues,
    ) => {
      formFieldsNames: Array<keyof CandidateStatusFormFields> | null;
      autoupdatedFields: Partial<CandidateAutoupdatedFields> | null;
    }
  >
> = {
  ...AllClosedStatuses.reduce(
    (acc, status) => {
      return {
        ...acc,
        [status]: (stage: CandidateStatusStage) => {
          const stageSpecificFields = StageFailedAggregatedStatueses[stage];
          return {
            formFieldsNames: ClosingStatusesWithoutFields.includes(status)
              ? null
              : stageSpecificFields.formFieldsNames,
            autoupdatedFields: {
              ...(stageSpecificFields.autoupdatedFields || {}),
              State_of_Candidate_NEW: status
                .toLowerCase()
                .includes('disqualified')
                ? 'Closed Disqualified Lead'
                : 'Closed Qualified Lead',
              Lead_closed_because_NEW:
                LeadClosedBecauseByCandidateStatus[status],
              Date_of_lead_closing: format(new Date(), 'yyyy-MM-dd'),
            },
          };
        },
      };
    },
    {} as {
      formFieldsNames: Array<keyof CandidateStatusFormFields> | null;
      autoupdatedFields: Partial<CandidateAutoupdatedFields> | null;
    },
  ),
  [CandidateStatus.Interested]: () => ({
    formFieldsNames: [
      'Source',
      'Type_of_channel',
      'Type_of_Inbound_channel',
      'Type_of_Outbound_channel',
      'Interested_in_JO',
      'LeadGen_owner',
    ],
    autoupdatedFields: null,
  }),
  [CandidateStatus.Applied]: (stage, formValues) => ({
    formFieldsNames: ['Date_of_last_apply'],
    autoupdatedFields: {
      Date_of_processing: formValues.Date_of_last_apply,
    },
  }),
  [CandidateStatus.Selfgen]: (stage, formValues) => ({
    formFieldsNames: ['Date_of_last_apply'],
    autoupdatedFields: {
      Date_of_processing: formValues.Date_of_last_apply,
    },
  }),
  [CandidateStatus.VI_Sent]: () => ({
    formFieldsNames: ['VI_sent_date'],
    autoupdatedFields: {
      VI_result: [VideoInterviewStatus.Sent],
    },
  }),
  [CandidateStatus.VI_Done]: () => ({
    formFieldsNames: ['VI_date', 'Video_Interview_Link'],
    autoupdatedFields: {
      VI_result: [VideoInterviewStatus.Sent, VideoInterviewStatus.Done],
    },
  }),
  [CandidateStatus.VI_Passed]: () => ({
    formFieldsNames: ['VI_feedback'],
    autoupdatedFields: {
      VI_result: [
        VideoInterviewStatus.Sent,
        VideoInterviewStatus.Done,
        VideoInterviewStatus.Passed,
      ],
    },
  }),
  [CandidateStatus.VI_Bad_Soft_Skills]: (stage, formValues) => ({
    formFieldsNames: ['VI_feedback'],
    autoupdatedFields: {
      VI_result: [
        VideoInterviewStatus.Sent,
        VideoInterviewStatus.Done,
        VideoInterviewStatus.Failed,
      ],
      State_of_Candidate_NEW: 'Closed Qualified Lead',
      Lead_closed_because_NEW:
        LeadClosedBecauseByCandidateStatus[CandidateStatus.VI_Bad_Soft_Skills],
      Date_of_lead_closing: format(new Date(), 'yyyy-MM-dd'),
      ...(checkIfHasActiveIntroCall(formValues)
        ? {
            Calendly_calendar: 'Rejected by recruiter',
            Leads_reply_Date: null,
          }
        : {}),
    },
  }),
  [CandidateStatus.VI_Wrong_Answers]: (stage, formValues) => ({
    formFieldsNames: ['VI_feedback'],
    autoupdatedFields: {
      VI_result: [
        VideoInterviewStatus.Sent,
        VideoInterviewStatus.Done,
        VideoInterviewStatus.Failed,
      ],
      State_of_Candidate_NEW: 'Closed Qualified Lead',
      Lead_closed_because_NEW:
        LeadClosedBecauseByCandidateStatus[CandidateStatus.VI_Bad_Soft_Skills],
      Date_of_lead_closing: format(new Date(), 'yyyy-MM-dd'),
      ...(checkIfHasActiveIntroCall(formValues)
        ? {
            Calendly_calendar: 'Rejected by recruiter',
            Leads_reply_Date: null,
          }
        : {}),
    },
  }),
  [CandidateStatus.VI_Incomplete]: (stage, formValues) => ({
    formFieldsNames: ['VI_feedback'],
    autoupdatedFields: {
      VI_result: [
        VideoInterviewStatus.Sent,
        VideoInterviewStatus.Done,
        VideoInterviewStatus.Failed,
      ],
      State_of_Candidate_NEW: 'Closed Qualified Lead',
      Lead_closed_because_NEW:
        LeadClosedBecauseByCandidateStatus[CandidateStatus.VI_Bad_Soft_Skills],
      Date_of_lead_closing: format(new Date(), 'yyyy-MM-dd'),
      ...(checkIfHasActiveIntroCall(formValues)
        ? {
            Calendly_calendar: 'Rejected by recruiter',
            Leads_reply_Date: null,
          }
        : {}),
    },
  }),
  [CandidateStatus.VI_Low_English]: (stage, formValues) => ({
    formFieldsNames: ['VI_feedback'],
    autoupdatedFields: {
      VI_result: [
        VideoInterviewStatus.Sent,
        VideoInterviewStatus.Done,
        VideoInterviewStatus.Failed,
      ],
      State_of_Candidate_NEW: 'Closed Qualified Lead',
      Lead_closed_because_NEW:
        LeadClosedBecauseByCandidateStatus[CandidateStatus.VI_Bad_Soft_Skills],
      Date_of_lead_closing: format(new Date(), 'yyyy-MM-dd'),
      ...(checkIfHasActiveIntroCall(formValues)
        ? {
            Calendly_calendar: 'Rejected by recruiter',
            Leads_reply_Date: null,
          }
        : {}),
    },
  }),
  [CandidateStatus.TT_Sent]: () => ({
    formFieldsNames: [
      'Test_Task_sent_date',
      'Test_Task_sent_by',
      'Test_Task_Technology',
    ],
    autoupdatedFields: {
      Test_Task: [TestTaskStatus.SENT],
    },
  }),
  [CandidateStatus.TT_Done]: () => ({
    formFieldsNames: ['Test_Task_Date'],
    autoupdatedFields: {
      Test_Task: [TestTaskStatus.SENT, TestTaskStatus.DONE],
    },
  }),
  [CandidateStatus.TT_Passed]: () => ({
    formFieldsNames: [
      'Codility_Result',
      'Test_Task_Link',
      'Test_Task_Feedback',
    ],
    autoupdatedFields: {
      Test_Task: [
        TestTaskStatus.SENT,
        TestTaskStatus.DONE,
        TestTaskStatus.PASSED,
      ],
    },
  }),
  [CandidateStatus.TT_Failed]: () => ({
    formFieldsNames: [
      'Codility_Result',
      'Test_Task_Link',
      'Test_Task_Feedback',
    ],
    autoupdatedFields: {
      Test_Task: [
        TestTaskStatus.SENT,
        TestTaskStatus.DONE,
        TestTaskStatus.FAILED,
      ],
      State_of_Candidate_NEW: 'Closed Qualified Lead',
      Lead_closed_because_NEW:
        LeadClosedBecauseByCandidateStatus[CandidateStatus.TT_Failed],
      Date_of_lead_closing: format(new Date(), 'yyyy-MM-dd'),
    },
  }),
  [CandidateStatus.TT_Refused]: () => ({
    formFieldsNames: null,
    autoupdatedFields: null,
  }),
  [CandidateStatus.IC_Waitlist]: () => ({
    formFieldsNames: null,
    autoupdatedFields: null,
  }),
  [CandidateStatus.IC_Scheduled]: () => ({
    formFieldsNames: [
      'Leads_owner_2018',
      'Date_of_Prescreen_Planned',
      'Leads_reply_Date',
    ],
    autoupdatedFields: {
      Calendly_calendar: 'Meeting scheduled',
      Recruiters_Feedback: '',
      Date_of_Prescreen: '',
      Talked_with: [],
      Prescreen_failed_because: '',
    },
  }),
  [CandidateStatus.IC_Done]: (_, formValues: CandidateStatusFormValues) => ({
    formFieldsNames: [
      'Email',
      'First_Name',
      'Last_Name',
      'Dev_-_QA-AQA',
      'Primary_Skill_Set',
      'Secondary_Skill_Set',
      'Skill_Set',
      'Full_Stack',
      'Type_of_Developer',
      'Technical_Flow',
      'English_level',
      'Seniority_Level',
      'LinkedIn_URL',
      'Location_Country',
      'Location_City',
      'Hourly_Rate_Expected',
      'Hourly_Rate_Current',
      'Notice_period_from_Offer',
      'Current_Company',
      'Current_employment_type',
      'Expected_employment_type',
      'Talked_with',
      'Date_of_Prescreen',
    ],
    autoupdatedFields: {
      Seniority_Start_Date: format(
        sub(new Date(), {
          years:
            SeniorityToYearsOfExperience[
              formValues.Seniority_Level as string
            ] || 0,
        }),
        'yyyy-MM-dd',
      ),
      New_Location:
        formValues.Location_City === 'Other'
          ? formValues.Location_Country
          : formValues.Location_City,
      Location_Area: formValues.Location_Country
        ? LocationDetailsByCountry[formValues.Location_Country]?.area || 'Other'
        : 'Other',
      Time_zone: formValues.Location_Country
        ? LocationDetailsByCountry[formValues.Location_Country]?.timezone || ''
        : '',
    },
  }),
  [CandidateStatus.IC_Passed]: () => ({
    formFieldsNames: ['Recruiters_Feedback'],
    autoupdatedFields: null,
  }),
  [CandidateStatus.IC_Rejected_By_Recruiter_PositionClosed]: () => ({
    formFieldsNames: ['Recruiters_Feedback'],
    autoupdatedFields: {
      State_of_Candidate_NEW: 'Closed Qualified Lead',
      Lead_closed_because_NEW:
        LeadClosedBecauseByCandidateStatus[
          CandidateStatus.IC_Rejected_By_Recruiter_PositionClosed
        ],
      Date_of_lead_closing: format(new Date(), 'yyyy-MM-dd'),
      Calendly_calendar: 'Rejected by recruiter',
      Leads_reply_Date: null,
    },
  }),
  [CandidateStatus.IC_Rejected_By_Recruiter_Location]: () => ({
    formFieldsNames: ['Recruiters_Feedback'],
    autoupdatedFields: {
      State_of_Candidate_NEW: 'Closed Qualified Lead',
      Lead_closed_because_NEW:
        LeadClosedBecauseByCandidateStatus[
          CandidateStatus.IC_Rejected_By_Recruiter_Location
        ],
      Date_of_lead_closing: format(new Date(), 'yyyy-MM-dd'),
      Calendly_calendar: 'Rejected by recruiter',
      Leads_reply_Date: null,
    },
  }),
  [CandidateStatus.IC_Rejected_By_Recruiter_SkillSet]: () => ({
    formFieldsNames: ['Recruiters_Feedback'],
    autoupdatedFields: {
      State_of_Candidate_NEW: 'Closed Qualified Lead',
      Lead_closed_because_NEW:
        LeadClosedBecauseByCandidateStatus[
          CandidateStatus.IC_Rejected_By_Recruiter_SkillSet
        ],
      Date_of_lead_closing: format(new Date(), 'yyyy-MM-dd'),

      Calendly_calendar: 'Rejected by recruiter',
      Leads_reply_Date: null,
    },
  }),
  [CandidateStatus.IC_Rejected_By_Recruiter_Capacity]: () => ({
    formFieldsNames: ['Recruiters_Feedback'],
    autoupdatedFields: {
      State_of_Candidate_NEW: 'Closed Qualified Lead',
      Lead_closed_because_NEW:
        LeadClosedBecauseByCandidateStatus[
          CandidateStatus.IC_Rejected_By_Recruiter_Capacity
        ],
      Date_of_lead_closing: format(new Date(), 'yyyy-MM-dd'),
      Calendly_calendar: 'Rejected by recruiter',
      Leads_reply_Date: null,
    },
  }),
  [CandidateStatus.IC_Canceled_By_Candidate]: () => ({
    formFieldsNames: null,
    autoupdatedFields: {
      State_of_Candidate_NEW: 'Closed Qualified Lead',
      Lead_closed_because_NEW:
        LeadClosedBecauseByCandidateStatus[
          CandidateStatus.IC_Canceled_By_Candidate
        ],
      Date_of_lead_closing: format(new Date(), 'yyyy-MM-dd'),
      Calendly_calendar: 'Cancelled by candidate',
      Leads_owner_2018: null,
      Leads_reply_Date: null,
    },
  }),
  [CandidateStatus.IC_Canceled_By_Recruiter]: () => ({
    formFieldsNames: ['Lead_closed_because_NEW', 'Recruiters_Feedback'],
    autoupdatedFields: {
      State_of_Candidate_NEW: 'Closed Qualified Lead',
      Date_of_lead_closing: format(new Date(), 'yyyy-MM-dd'),
      Calendly_calendar: 'Cancelled by recruiter',
    },
  }),
  [CandidateStatus.IC_No_Show]: () => ({
    formFieldsNames: null,
    autoupdatedFields: {
      State_of_Candidate_NEW: 'Closed Qualified Lead',
      Lead_closed_because_NEW:
        LeadClosedBecauseByCandidateStatus[CandidateStatus.IC_No_Show],
      Date_of_lead_closing: format(new Date(), 'yyyy-MM-dd'),
      Calendly_calendar: 'Call was missed by Candidate',
      Leads_owner_2018: null,
      Leads_reply_Date: null,
    },
  }),
  [CandidateStatus.Setting_TI]: () => ({
    formFieldsNames: null,
    autoupdatedFields: null,
  }),
  [CandidateStatus.TI_Scheduled]: () => ({
    formFieldsNames: ['Date_of_Tech_Interview_planned', 'Test_Task_Reviewers'],
    autoupdatedFields: {
      Interview: [TechnicalInterviewStatus.Scheduled],
    },
  }),
  [CandidateStatus.TI_Done]: () => ({
    formFieldsNames: null,
    autoupdatedFields: {
      Interview: [
        TechnicalInterviewStatus.Scheduled,
        TechnicalInterviewStatus.Interviewed,
      ],
    },
  }),
  [CandidateStatus.TI_Passed]: () => ({
    formFieldsNames: ['TI_Suggested_Seniority', 'Technical_Conslusion'],
    autoupdatedFields: {
      Interview: [
        TechnicalInterviewStatus.Scheduled,
        TechnicalInterviewStatus.Interviewed,
        TechnicalInterviewStatus.Passed,
      ],
    },
  }),
  [CandidateStatus.TI_Passed_Lower_Seniority]: () => ({
    formFieldsNames: ['Technical_Conslusion'],
    autoupdatedFields: {
      Interview: [
        TechnicalInterviewStatus.Scheduled,
        TechnicalInterviewStatus.Interviewed,
        TechnicalInterviewStatus.Passed,
      ],
    },
  }),
  [CandidateStatus.TI_Failed_For_Project]: () => ({
    formFieldsNames: ['Technical_Conslusion'],
    autoupdatedFields: {
      Interview: [
        TechnicalInterviewStatus.Scheduled,
        TechnicalInterviewStatus.Interviewed,
        TechnicalInterviewStatus.Passed,
      ],
      State_of_Candidate_NEW: 'Closed Qualified Lead',
      Lead_closed_because_NEW:
        LeadClosedBecauseByCandidateStatus[
          CandidateStatus.No_Suitable_After_TI_Passed
        ],
      Date_of_lead_closing: format(new Date(), 'yyyy-MM-dd'),
    },
  }),
  [CandidateStatus.Setting_TL_Intro]: () => ({
    formFieldsNames: null,
    autoupdatedFields: null,
  }),
  [CandidateStatus.TL_Intro_Scheduled]: () => ({
    formFieldsNames: ['TL_intro_date', 'TL_Intro'],
    autoupdatedFields: {
      Interview: [
        TechnicalInterviewStatus.Scheduled,
        TechnicalInterviewStatus.Interviewed,
        TechnicalInterviewStatus.TLScheduled,
      ],
    },
  }),
  [CandidateStatus.TL_Intro_Done]: () => ({
    formFieldsNames: null,
    autoupdatedFields: {
      Interview: [
        TechnicalInterviewStatus.Scheduled,
        TechnicalInterviewStatus.Interviewed,
        TechnicalInterviewStatus.TLScheduled,
        TechnicalInterviewStatus.TLInterviewed,
      ],
    },
  }),
  [CandidateStatus.TL_Intro_Passed]: () => ({
    formFieldsNames: ['TL_intro_feedback'],
    autoupdatedFields: {
      Interview: [
        TechnicalInterviewStatus.Scheduled,
        TechnicalInterviewStatus.Interviewed,
        TechnicalInterviewStatus.TLScheduled,
        TechnicalInterviewStatus.TLInterviewed,
        TechnicalInterviewStatus.Passed,
      ],
    },
  }),
  [CandidateStatus.Preparing_Submission]: () => ({
    formFieldsNames: null,
    autoupdatedFields: null,
  }),
  [CandidateStatus.Submitted]: () => ({
    formFieldsNames: [
      'Clients_Submission',
      'Date_of_Submission',
      'Date_of_Submission_Reply',
      'AE_Formatted_CV_Link',
    ],
    autoupdatedFields: {
      Submitted_to_client: true,
    },
  }),
  [CandidateStatus.CI_Scheduled]: () => ({
    formFieldsNames: ['Date_of_Clients_Interview', 'Date_of_Submission_Reply'],
    autoupdatedFields: null,
  }),
  [CandidateStatus.CI_Done]: () => ({
    formFieldsNames: ['Date_of_Submission_Reply'],
    autoupdatedFields: {
      Clients_Interview: [ClientInterviewStatus.Interviewed],
    },
  }),
  [CandidateStatus.CI_Passed]: () => ({
    formFieldsNames: [
      "Client's_Feedback",
      'Final_decision_from_client',
      'Date_of_Submission_Reply',
    ],
    autoupdatedFields: {
      Clients_Interview: [
        ClientInterviewStatus.Interviewed,
        ClientInterviewStatus.Passed,
      ],
    },
  }),
  [CandidateStatus.SOW_Signing]: () => ({
    formFieldsNames: null,
    autoupdatedFields: null,
  }),
  [CandidateStatus.SOW_Signed]: () => ({
    formFieldsNames: ['SOW_signed_date'],
    autoupdatedFields: null,
  }),
  [CandidateStatus.Offer_Sent]: () => ({
    formFieldsNames: ['Hourly_Rate_Offer1', 'Date_of_offer_done'],
    autoupdatedFields: null,
  }),
  [CandidateStatus.Hired]: () => ({
    formFieldsNames: ['Hot_Pipeline_End_Date'],
    autoupdatedFields: {
      State_of_Candidate_NEW: 'Hired',
      Date_of_lead_closing: format(new Date(), 'yyyy-MM-dd'),
      Lead_closed_because_NEW: 'Hired',
      Hot_Pipeline: false,
    },
  }),
  [CandidateStatus.Hired_NewHome]: () => ({
    formFieldsNames: null,
    autoupdatedFields: {
      State_of_Candidate_NEW: 'Hired',
      Date_of_lead_closing: format(new Date(), 'yyyy-MM-dd'),
      Lead_closed_because_NEW: 'Hired from New Home',
    },
  }),
  [CandidateStatus.OnHold]: () => ({
    formFieldsNames: null,
    autoupdatedFields: null,
  }),

  [CandidateStatus.In_Hot_Pipeline]: () => ({
    formFieldsNames: ['Hot_Pipeline_Start_Date'],
    autoupdatedFields: {
      Hot_Pipeline: true,
    },
  }),

  [CandidateStatus.No_Suitable_Openings_After_HotPipeline]: () => ({
    formFieldsNames: ['Hot_Pipeline_End_Date'],
    autoupdatedFields: {
      Hot_Pipeline: false,
      State_of_Candidate_NEW: 'Closed Qualified Lead',
      Lead_closed_because_NEW:
        LeadClosedBecauseByCandidateStatus[
          CandidateStatus.No_Suitable_Openings_After_HotPipeline
        ],
    },
  }),
};

export const RequiredCandidateStatusFieldsByStatus: {
  [k in keyof CandidateStatusFormFields]?: {
    status: CandidateStatus;
    stage: CandidateStatusStage;
  }[];
} = {
  Source: [
    {
      status: CandidateStatus.Interested,
      stage: CandidateStatusStage.Application,
    },
  ],
  Type_of_channel: [
    {
      status: CandidateStatus.Interested,
      stage: CandidateStatusStage.Application,
    },
  ],
  LeadGen_owner: [
    {
      status: CandidateStatus.Interested,
      stage: CandidateStatusStage.Application,
    },
  ],

  Date_of_last_apply: [
    {
      status: CandidateStatus.Applied,
      stage: CandidateStatusStage.Application,
    },
  ],

  VI_sent_date: [
    {
      status: CandidateStatus.VI_Sent,
      stage: CandidateStatusStage.VideoInterview,
    },
  ],

  VI_date: [
    {
      status: CandidateStatus.VI_Done,
      stage: CandidateStatusStage.VideoInterview,
    },
  ],
  Video_Interview_Link: [
    {
      status: CandidateStatus.VI_Done,
      stage: CandidateStatusStage.VideoInterview,
    },
  ],

  VI_feedback: [
    {
      status: CandidateStatus.VI_Passed,
      stage: CandidateStatusStage.VideoInterview,
    },
    ...ClosingStatusesByStage[CandidateStatusStage.VideoInterview]
      .concat(AcceptedAnotherOfferStatuses)
      .concat(DisqualifiedStatuses)
      .concat(NoSuitableOpeningsStatuses)
      .concat(NonStageRelatedStatuses)
      .filter((e) => !ClosingStatusesWithoutFields.includes(e))
      .map((status) => ({
        status,
        stage: CandidateStatusStage.VideoInterview,
      })),
  ],

  Test_Task_Technology: [
    { status: CandidateStatus.TT_Sent, stage: CandidateStatusStage.TestTask },
  ],
  Test_Task_sent_by: [
    { status: CandidateStatus.TT_Sent, stage: CandidateStatusStage.TestTask },
  ],
  Test_Task_sent_date: [
    { status: CandidateStatus.TT_Sent, stage: CandidateStatusStage.TestTask },
  ],

  Test_Task_Date: [
    { status: CandidateStatus.TT_Done, stage: CandidateStatusStage.TestTask },
  ],

  Codility_Result: [
    { status: CandidateStatus.TT_Passed, stage: CandidateStatusStage.TestTask },
    { status: CandidateStatus.TT_Failed, stage: CandidateStatusStage.TestTask },
  ],

  Leads_owner_2018: [
    { status: CandidateStatus.IC_Scheduled, stage: CandidateStatusStage.IC },
  ],
  Date_of_Prescreen_Planned: [
    { status: CandidateStatus.IC_Scheduled, stage: CandidateStatusStage.IC },
  ],
  Leads_reply_Date: [
    { status: CandidateStatus.IC_Scheduled, stage: CandidateStatusStage.IC },
  ],

  Email: [{ status: CandidateStatus.IC_Done, stage: CandidateStatusStage.IC }],
  First_Name: [
    { status: CandidateStatus.IC_Done, stage: CandidateStatusStage.IC },
  ],
  Last_Name: [
    { status: CandidateStatus.IC_Done, stage: CandidateStatusStage.IC },
  ],
  'Dev_-_QA-AQA': [
    { status: CandidateStatus.IC_Done, stage: CandidateStatusStage.IC },
  ],
  Technical_Flow: [
    { status: CandidateStatus.IC_Done, stage: CandidateStatusStage.IC },
  ],
  English_level: [
    { status: CandidateStatus.IC_Done, stage: CandidateStatusStage.IC },
  ],
  Seniority_Level: [
    { status: CandidateStatus.IC_Done, stage: CandidateStatusStage.IC },
  ],
  Location_Country: [
    { status: CandidateStatus.IC_Done, stage: CandidateStatusStage.IC },
  ],
  Location_City: [
    { status: CandidateStatus.IC_Done, stage: CandidateStatusStage.IC },
  ],
  Hourly_Rate_Expected: [
    { status: CandidateStatus.IC_Done, stage: CandidateStatusStage.IC },
  ],
  Notice_period_from_Offer: [
    { status: CandidateStatus.IC_Done, stage: CandidateStatusStage.IC },
  ],
  Talked_with: [
    { status: CandidateStatus.IC_Done, stage: CandidateStatusStage.IC },
  ],
  Date_of_Prescreen: [
    { status: CandidateStatus.IC_Done, stage: CandidateStatusStage.IC },
  ],

  Lead_closed_because_NEW: [
    {
      status: CandidateStatus.IC_Canceled_By_Recruiter,
      stage: CandidateStatusStage.IC,
    },
  ],
  Recruiters_Feedback: [
    { status: CandidateStatus.IC_Passed, stage: CandidateStatusStage.IC },
    ...ClosingStatusesByStage[CandidateStatusStage.IC]
      .concat(AcceptedAnotherOfferStatuses)
      .concat(DisqualifiedStatuses)
      .concat(NonStageRelatedStatuses)
      .filter(
        (e) =>
          ![
            CandidateStatus.IC_Rejected_By_Recruiter_PositionClosed,
            CandidateStatus.IC_Rejected_By_Recruiter_Location,
            CandidateStatus.IC_Rejected_By_Recruiter_SkillSet,
            CandidateStatus.IC_Rejected_By_Recruiter_Capacity,
            CandidateStatus.IC_Canceled_By_Candidate,
            CandidateStatus.IC_Canceled_By_Recruiter,
            CandidateStatus.IC_No_Show,
            ...ClosingStatusesWithoutFields,
          ].includes(e),
      )
      .map((status) => ({
        status,
        stage: CandidateStatusStage.IC,
      })),
  ],
  Prescreen_failed_because: [
    ...ClosingStatusesByStage[CandidateStatusStage.IC]
      .concat(AcceptedAnotherOfferStatuses)
      .concat(DisqualifiedStatuses)
      .concat(NonStageRelatedStatuses)
      .filter(
        (e) =>
          ![
            CandidateStatus.IC_Rejected_By_Recruiter_PositionClosed,
            CandidateStatus.IC_Rejected_By_Recruiter_Location,
            CandidateStatus.IC_Rejected_By_Recruiter_SkillSet,
            CandidateStatus.IC_Rejected_By_Recruiter_Capacity,
            CandidateStatus.IC_Canceled_By_Candidate,
            CandidateStatus.IC_Canceled_By_Recruiter,
            CandidateStatus.IC_No_Show,
            ...ClosingStatusesWithoutFields,
          ].includes(e),
      )
      .map((status) => ({
        status,
        stage: CandidateStatusStage.IC,
      })),
  ],

  Test_Task_Reviewers: [
    { status: CandidateStatus.TI_Scheduled, stage: CandidateStatusStage.TI },
  ],
  Date_of_Tech_Interview_planned: [
    { status: CandidateStatus.TI_Scheduled, stage: CandidateStatusStage.TI },
  ],

  Technical_Conslusion: [
    { status: CandidateStatus.TI_Passed, stage: CandidateStatusStage.TI },
    {
      status: CandidateStatus.TI_Passed_Lower_Seniority,
      stage: CandidateStatusStage.TI,
    },
    ...ClosingStatusesByStage[CandidateStatusStage.TI]
      .concat(AcceptedAnotherOfferStatuses)
      .concat(DisqualifiedStatuses)
      .concat(NoSuitableOpeningsStatuses)
      .concat(NonStageRelatedStatuses)
      .filter((e) => !ClosingStatusesWithoutFields.includes(e))
      .map((status) => ({
        status,
        stage: CandidateStatusStage.TI,
      })),
  ],

  TL_Intro: [
    {
      status: CandidateStatus.TL_Intro_Scheduled,
      stage: CandidateStatusStage.TLIntro,
    },
  ],
  TL_intro_date: [
    {
      status: CandidateStatus.TL_Intro_Scheduled,
      stage: CandidateStatusStage.TLIntro,
    },
  ],

  TL_intro_feedback: [
    {
      status: CandidateStatus.TL_Intro_Passed,
      stage: CandidateStatusStage.TLIntro,
    },
    ...ClosingStatusesByStage[CandidateStatusStage.TLIntro]
      .concat(AcceptedAnotherOfferStatuses)
      .concat(DisqualifiedStatuses)
      .concat(NoSuitableOpeningsStatuses)
      .concat(NonStageRelatedStatuses)
      .filter((e) => !ClosingStatusesWithoutFields.includes(e))
      .map((status) => ({
        status,
        stage: CandidateStatusStage.TLIntro,
      })),
  ],

  Clients_Submission: [
    {
      status: CandidateStatus.Submitted,
      stage: CandidateStatusStage.Submission,
    },
  ],
  Date_of_Submission: [
    {
      status: CandidateStatus.Submitted,
      stage: CandidateStatusStage.Submission,
    },
  ],

  Date_of_Submission_Reply: [
    ...ClosingStatusesByStage[CandidateStatusStage.CI],
    ...AcceptedAnotherOfferStatuses,
    ...DisqualifiedStatuses,
    ...NoSuitableOpeningsStatuses,
    ...NonStageRelatedStatuses,
    ...ClosingStatusesByStage[CandidateStatusStage.Submission],
    {
      status: CandidateStatus.Submitted,
      stage: CandidateStatusStage.Submission,
    },
    {
      status: CandidateStatus.CI_Passed,
      stage: CandidateStatusStage.CI,
    },
    {
      status: CandidateStatus.CI_Scheduled,
      stage: CandidateStatusStage.CI,
    },
    {
      status: CandidateStatus.CI_Done,
      stage: CandidateStatusStage.CI,
    },
  ].map((status) =>
    typeof status === 'string'
      ? {
          status,
          stage: status.includes('CI_')
            ? CandidateStatusStage.CI
            : CandidateStatusStage.Submission,
        }
      : status,
  ),
  Date_of_Clients_Interview: [
    { status: CandidateStatus.CI_Scheduled, stage: CandidateStatusStage.CI },
  ],

  Final_decision_from_client: [
    { status: CandidateStatus.CI_Passed, stage: CandidateStatusStage.CI },
    ...ClosingStatusesByStage[CandidateStatusStage.CI]
      .concat(AcceptedAnotherOfferStatuses)
      .concat(DisqualifiedStatuses)
      .concat(NoSuitableOpeningsStatuses)
      .concat(NonStageRelatedStatuses)
      .filter(
        (e) =>
          ![
            CandidateStatus.ClientStoppedReplying,
            ...ClosingStatusesWithoutFields,
          ].includes(e),
      )
      .map((status) => ({
        status,
        stage: CandidateStatusStage.CI,
      })),
  ],
  "Client's_Feedback": [
    { status: CandidateStatus.CI_Passed, stage: CandidateStatusStage.CI },
    ...ClosingStatusesByStage[CandidateStatusStage.CI]
      .concat(AcceptedAnotherOfferStatuses)
      .concat(DisqualifiedStatuses)
      .concat(NoSuitableOpeningsStatuses)
      .concat(NonStageRelatedStatuses)
      .filter(
        (e) =>
          ![
            CandidateStatus.ClientStoppedReplying,
            ...ClosingStatusesWithoutFields,
          ].includes(e),
      )
      .map((status) => ({
        status,
        stage: CandidateStatusStage.CI,
      })),
  ],

  SOW_signed_date: [
    { status: CandidateStatus.SOW_Signed, stage: CandidateStatusStage.SOW },
  ],

  Hourly_Rate_Offer1: [
    { status: CandidateStatus.Offer_Sent, stage: CandidateStatusStage.Hiring },
  ],
  Date_of_offer_done: [
    { status: CandidateStatus.Offer_Sent, stage: CandidateStatusStage.Hiring },
  ],
  Hot_Pipeline_Start_Date: [
    ...Object.values(CandidateStatusStage).map((stage) => ({
      status: CandidateStatus.In_Hot_Pipeline,
      stage,
    })),
  ],

  Hot_Pipeline_End_Date: [
    ...Object.values(CandidateStatusStage).map((stage) => ({
      status: CandidateStatus.No_Suitable_Openings_After_HotPipeline,
      stage,
    })),
  ],
};

export enum NotifyCandidateOption {
  None = 'None',
  Default = 'Default',
  DefaultWithTask = 'DefaultWithTask',
  Custom = 'Custom',
}
