import React, { useState } from 'react';
import { FieldArray, Formik, Form } from 'formik';
import * as Yup from 'yup';

import {
  Grid,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
  Box,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import PhotoSizeSelectSmallOutlinedIcon from '@mui/icons-material/PhotoSizeSelectSmallOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import PlaylistAddOutlinedIcon from '@mui/icons-material/PlaylistAddOutlined';
import { CustomButton, VIQuestionsList } from '@components';

import { VideoInterviewQuestion } from '@types';

const validationSchema = Yup.object({
  questions: Yup.array()
    .of(
      Yup.object({
        title: Yup.string().required('Question title is required'),
        preparation: Yup.number()
          .min(0, 'Preparation time cannot be negative')
          .required('Preparation time is required'),
        duration: Yup.number()
          .min(0, 'Duration cannot be negative')
          .required('Answer duration is required'),
      }),
    )
    .min(1, 'At least one question is required'),
});

export const VideoInterviewQuestions: React.FC<{
  questions: VideoInterviewQuestion[] | null;
  onChange: (updatedQuestion: VideoInterviewQuestion[]) => void;
}> = ({ questions, onChange }) => {
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down('md'));

  const [isQuestionVisible, setIsQuestionVisible] = useState(false);
  const [isEditFormVisible, setIsEditFormVisible] = useState(false);

  const toggleQuestionVisibility = () => {
    setIsQuestionVisible((prev) => !prev);
  };

  if (!questions?.length) return null;

  return (
    <>
      {!isEditFormVisible && (
        <Grid container alignItems="center" spacing={1}>
          <Grid item>
            <CustomButton
              size="small"
              color="secondary"
              label={isQuestionVisible ? 'Hide Questions' : 'Show Questions'}
              startIcon={
                isQuestionVisible ? (
                  <PhotoSizeSelectSmallOutlinedIcon fontSize="small" />
                ) : (
                  <ListOutlinedIcon fontSize="small" />
                )
              }
              onClick={toggleQuestionVisibility}
            />
          </Grid>
          <Grid item>
            <CustomButton
              size="small"
              color="secondary"
              label="Customize"
              startIcon={<EditOutlinedIcon fontSize="small" />}
              onClick={() => setIsEditFormVisible(true)}
            />
          </Grid>
        </Grid>
      )}

      {isQuestionVisible && !isEditFormVisible && questions?.length && (
        <VIQuestionsList questionsList={questions} />
      )}

      {isEditFormVisible && (
        <Formik
          initialValues={{
            questions,
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            onChange(values.questions.filter((q) => q.title.trim() !== ''));

            setIsEditFormVisible(false);
          }}
        >
          {({ values, handleChange, handleBlur, isValid }) => (
            <Box
              sx={{
                border: '6px solid #F6F8FC',
                width: '100%',
                padding: '20px',
                borderRadius: '6px',
                paddingBottom: '20px',
              }}
            >
              <Form>
                <FieldArray
                  name="questions"
                  render={(arrayHelpers) => (
                    <>
                      {values.questions.map((question, index) => (
                        <Grid
                          key={question.questionNumber}
                          container
                          md={12}
                          alignItems="start"
                          spacing={2}
                          justifyContent="start"
                          mt={1}
                        >
                          <Grid item sm={6} lg={6}>
                            <TextField
                              id={`questions.${index}.title`}
                              label={`Question ${question.questionNumber}`}
                              name={`questions.${index}.title`}
                              value={question.title || ''}
                              multiline
                              maxRows={4}
                              fullWidth
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Grid>
                          <Grid item sm={2} lg={2}>
                            <TextField
                              id={`questions.${index}.preparation`}
                              name={`questions.${index}.preparation`}
                              type="number"
                              value={question.preparation}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              label="Preparation"
                              fullWidth
                              InputProps={{
                                endAdornment: !isSmallDevice ? (
                                  <InputAdornment position="end">
                                    min
                                  </InputAdornment>
                                ) : null,
                              }}
                            />
                          </Grid>
                          <Grid item sm={2} lg={2}>
                            <TextField
                              id={`questions.${index}.duration`}
                              name={`questions.${index}.duration`}
                              type="number"
                              value={question.duration}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              label="Answer"
                              fullWidth
                              InputProps={{
                                endAdornment: !isSmallDevice ? (
                                  <InputAdornment position="end">
                                    min
                                  </InputAdornment>
                                ) : null,
                              }}
                            />
                          </Grid>
                          <Grid item xs={1} container justifyContent="center">
                            <IconButton
                              color="secondary"
                              onClick={() => {
                                arrayHelpers.remove(index);
                              }}
                            >
                              <DeleteOutlineOutlinedIcon fontSize="medium" />
                            </IconButton>
                          </Grid>
                        </Grid>
                      ))}

                      <Grid container md={2} mt={2} mb={2}>
                        <CustomButton
                          size="small"
                          color="secondary"
                          label="Add question"
                          startIcon={
                            <PlaylistAddOutlinedIcon fontSize="small" />
                          }
                          onClick={() =>
                            arrayHelpers.push({
                              questionNumber: values.questions.length + 1,
                              title: '',
                              preparation: 0,
                              duration: 0,
                            })
                          }
                        />
                      </Grid>
                    </>
                  )}
                />

                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    <CustomButton
                      size="small"
                      label="Save"
                      type="submit"
                      isDisabled={!isValid}
                    />
                  </Grid>

                  <Grid item>
                    <CustomButton
                      size="small"
                      label="Cancel"
                      color="secondary"
                      onClick={() => setIsEditFormVisible(false)}
                    />
                  </Grid>

                  <Grid item ml={4}>
                    <Typography variant="body1" color="text.secondary">
                      Adjusted questions will be saved to draft after clicking
                      save
                    </Typography>
                  </Grid>
                </Grid>
              </Form>
            </Box>
          )}
        </Formik>
      )}
    </>
  );
};
