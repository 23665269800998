import {
  ICandidatesOptions,
  IEntityMainTableHeaderCell,
  IZohoCandidate,
} from '@types';
import { TableHeaderCellAligment } from './table';

export enum CandidateSortingOptions {
  NONE = '',
  NAME = 'Full_Name',
  LOCATION = 'Location_Country',
  INTRO_DONE = 'Date_Of_Prescreen',
  RECRUITER_RESPONSIBLE = 'Leads_Owner_2018',
  ADDED_BY = 'Added_by',
  STATUS = 'Candidate_Status',
  TOM = 'TOM',
  NH_START = 'New_Home_Date_Start',
  NH_END = 'New_Home_Date_End',
  SENIORITY = 'Seniority_Level',
  TECH_FLOW = 'Technical_Flow',
}

export enum CandidateStage {
  APPLIED = 'applied',
  TT_PASSED = 'ttPassed',
  IC_SCHEDULED = 'icscheduled',
  IC_DONE = 'icdone',
  IC_PASSED = 'icPassed',
  TI_PASSED = 'tiPassed',
  SUBMITTED = 'submitted',
  NEW_HOME = 'newHome',
  IC_WAITLIST = 'icWaitlist',
}

export const CandidateStageLabel: Record<CandidateStage, string> = {
  [CandidateStage.APPLIED]: 'Applied',
  [CandidateStage.NEW_HOME]: 'NewHome',
  [CandidateStage.TT_PASSED]: 'TT passed',
  [CandidateStage.IC_SCHEDULED]: 'IC scheduled',
  [CandidateStage.IC_DONE]: 'IC done',
  [CandidateStage.IC_PASSED]: 'IC passed',
  [CandidateStage.TI_PASSED]: 'TI passed',
  [CandidateStage.SUBMITTED]: 'Submitted',
  [CandidateStage.IC_WAITLIST]: 'IC waitlist',
};

export const CandidateStagesOrder = [
  CandidateStage.APPLIED,
  CandidateStage.NEW_HOME,
  CandidateStage.TT_PASSED,
  CandidateStage.IC_WAITLIST,
  CandidateStage.IC_SCHEDULED,
  CandidateStage.IC_DONE,
  CandidateStage.IC_PASSED,
  CandidateStage.TI_PASSED,
  CandidateStage.SUBMITTED,
];

export enum CandidateUpcomingEvents {
  IC = 'IntroCall',
  TI = 'TechnicalInterview',
}

export const CandidateUpcomingEventsLabel: Record<
  CandidateUpcomingEvents,
  string
> = {
  [CandidateUpcomingEvents.IC]: 'IC',
  [CandidateUpcomingEvents.TI]: 'TI',
};

export enum CandidateState {
  Open = 'Open',
  Closed = 'Closed',
  Hired = 'Hired',
}

export const candidatesStageHeaders = [
  { label: 'Applied', value: CandidateStage.APPLIED },
  { label: 'TT passed', value: CandidateStage.TT_PASSED },
  { label: 'IC scheduled', value: CandidateStage.IC_SCHEDULED },
  { label: 'IC done', value: CandidateStage.IC_DONE },
  { label: 'IC passed', value: CandidateStage.IC_PASSED },
  { label: 'TI passed', value: CandidateStage.TI_PASSED },
  { label: 'Submitted', value: CandidateStage.SUBMITTED },
];

export enum CandidateTypeOfOutboundChannel {
  Selfgen = "Recruiter's self-gen",
}

export const CandidateTypeLabel: Record<
  CandidateTypeOfOutboundChannel,
  string
> = {
  [CandidateTypeOfOutboundChannel.Selfgen]: 'Self-gen',
};

export const tableHeadersCandidates: IEntityMainTableHeaderCell[] = [
  {
    label: 'Full name',
    value: CandidateSortingOptions.NAME,
    width: 230,
    align: TableHeaderCellAligment.Left,
    isSortable: true,
  },
  {
    label: 'Seniority',
    value: CandidateSortingOptions.SENIORITY,
    width: 100,
    align: TableHeaderCellAligment.Left,
    isSortable: true,
  },
  {
    label: 'Main stack',
    value: CandidateSortingOptions.TECH_FLOW,
    width: 260,
    align: TableHeaderCellAligment.Left,
    isSortable: true,
  },
  {
    label: 'Date of Intro Done',
    value: CandidateSortingOptions.INTRO_DONE,
    width: 160,
    align: TableHeaderCellAligment.Left,
    isSortable: true,
  },
  {
    label: 'Responsible recruiter',
    value: CandidateSortingOptions.RECRUITER_RESPONSIBLE,
    width: 213,
    align: TableHeaderCellAligment.Left,
    isSortable: true,
  },
  {
    label: 'Added by',
    value: CandidateSortingOptions.ADDED_BY,
    width: 213,
    align: TableHeaderCellAligment.Left,
    isSortable: true,
  },
  {
    label: 'Status',
    value: CandidateSortingOptions.STATUS,
    width: 209,
    align: TableHeaderCellAligment.Left,
    isSortable: true,
  },
  {
    label: 'Score',
    value: null,
    width: 124,
    align: TableHeaderCellAligment.Left,
    isSortable: false,
  },
];

export const tableHeadersNewHomers: IEntityMainTableHeaderCell[] = [
  {
    label: 'Full name',
    value: CandidateSortingOptions.NAME,
    width: 230,
    align: TableHeaderCellAligment.Left,
    isSortable: true,
  },
  {
    label: 'Seniority',
    value: CandidateSortingOptions.SENIORITY,
    width: 100,
    align: TableHeaderCellAligment.Left,
    isSortable: true,
  },
  {
    label: 'Main stack',
    value: CandidateSortingOptions.TECH_FLOW,
    width: 260,
    align: TableHeaderCellAligment.Left,
    isSortable: true,
  },
  {
    label: 'TOM',
    value: CandidateSortingOptions.NONE,
    width: 213,
    align: TableHeaderCellAligment.Left,
    isSortable: false,
  },
  {
    label: 'NH Start',
    value: CandidateSortingOptions.NH_START,
    width: 124,
    align: TableHeaderCellAligment.Left,
    isSortable: true,
  },
  {
    label: 'NH End',
    value: CandidateSortingOptions.NH_END,
    width: 140,
    align: TableHeaderCellAligment.Left,
    isSortable: true,
  },
  {
    label: 'Positions',
    value: CandidateSortingOptions.NONE,
    width: 100,
    align: TableHeaderCellAligment.Left,
    isSortable: false,
  },
];

export const CandidateSeniorityLevels = [
  'Trainee',
  'Junior',
  'Middle',
  'Senior',
  'Lead',
  'Architect',
  'C-level',
];

export const SeniorityToYearsOfExperience: {
  [key: string]: number;
} = {
  Trainee: 0,
  Junior: 2,
  Middle: 4,
  Senior: 6,
  Lead: 7,
  Architect: 7,
  'C-level': 10,
};

export const CandidateFilterFields: Array<keyof ICandidatesOptions> = [
  'state',
  'country',
  'seniority',
  'techFlow',
  'payRate',
  'stage',
  'recruiter',
  'addedBy',
];

export enum CandidateDetailsTabs {
  PROFILE = 'profile',
  VIDEO_INTERVIEW = 'video-interview',
  TEST_TASK = 'test-task',
  QUIZ = 'quiz',
  CALLS = 'calls',
  POSITIONS = 'positions',
  TIMELINE = 'timeline',
  MARKETING = 'marketing',
  SUBMISSIONS = 'submissions',
  NOTES = 'notes',
}

export const CandidateDetailsTabsOrder = [
  CandidateDetailsTabs.PROFILE,
  CandidateDetailsTabs.MARKETING,
  CandidateDetailsTabs.TEST_TASK,
  CandidateDetailsTabs.QUIZ,
  CandidateDetailsTabs.VIDEO_INTERVIEW,
  CandidateDetailsTabs.CALLS,
  CandidateDetailsTabs.POSITIONS,
  CandidateDetailsTabs.SUBMISSIONS,
  CandidateDetailsTabs.TIMELINE,
  CandidateDetailsTabs.NOTES,
];

export const CandidateDetailsTabLabel: Record<CandidateDetailsTabs, string> = {
  [CandidateDetailsTabs.PROFILE]: 'Profile',
  [CandidateDetailsTabs.MARKETING]: 'Marketing',
  [CandidateDetailsTabs.VIDEO_INTERVIEW]: 'Video',
  [CandidateDetailsTabs.TEST_TASK]: 'Test task',
  [CandidateDetailsTabs.QUIZ]: 'Quiz',
  [CandidateDetailsTabs.CALLS]: 'Interviews',
  [CandidateDetailsTabs.POSITIONS]: 'Positions',
  [CandidateDetailsTabs.SUBMISSIONS]: 'Submissions',
  [CandidateDetailsTabs.TIMELINE]: 'Timeline',
  [CandidateDetailsTabs.NOTES]: 'Notes',
};

export enum VideoInterviewStatus {
  Sent = 'Sent',
  Done = 'Done',
  Passed = 'Passed',
  Failed = 'Failed',
}

export const EnineeringSpecializations = [
  'AQA',
  'Data Analyst',
  'Business Analytic',
  'Data Engineer',
  'Data Scientist',
  'Database Administrator',
  'UI/UX Designer',
  'Developer',
  'DevOps',
  'QA',
  'TechOps',
  'AWS',
  'SDET',
  'UX Researcher',
];

export enum TechnicalInterviewStatus {
  Scheduled = 'Scheduled',
  Interviewed = 'Interviewed',
  Passed = 'Passed',
  Failed = 'Failed',
  TLScheduled = 'Sched-dTL',
  TLInterviewed = 'Interv-dTL',
  TLFailed = 'Fail-dTL',
}

export enum ClientInterviewStatus {
  Interviewed = 'Interviewed',
  Passed = 'Passed',
  Failed = 'Failed',
  TentativelyPassed = 'Tentatively Passed',
}

export const CandidateFieldsLabels: { [k in keyof IZohoCandidate]: string } = {
  id: 'ID',
  Email: 'Email',
  Email_from_CP: 'Email from LaunchPod',
  Second_Email: 'Second Email',
  Email_from_Linkedin: 'Email from LinkedIn',
  Lead_closed_because_NEW: 'Lead Closed Because',
  Lead_month_2018: 'Lead Month',
  Was_interested_month: 'Was Interested in Month',
  Leads_reply_2018: 'Leads Reply',
  Technical_Flow: 'Technical Flow',
  Notice_period_from_Offer: 'Notice Period',
  Additional_Info: 'Additional Info',
  Test_Task_Technology: 'Test Task Technology',
  Test_Task_Date: 'Test Task Result Date',
  Test_Task_sent_date: 'Test Task Sent Date',
  Codility_Result: 'Test Task Score',
  Tech_Quiz_Persent: 'Quiz Score',
  Test_Task: 'Test Task Status',
  Test_Task_Link: 'Test Task Result Link',
  Test_Task_sent_by: 'Test Task Sent by',
  Test_Task_Feedback: 'Test Task Feedback',
  TI_result: 'TI Result',
  Date_of_lead_closing: 'Date of Lead Closing',
  Assignee_Persona_2018: 'Assignee/Persona',
  All_Assignees_Personas: 'Historical Assignees/Personas',
  LinkedIn_URL: 'LinkedIn',
  Ex_Contractor: 'Ex-Contractor',
  Launch_Pod_Sign_in_date: 'LaunchPod Sign in Date',
  State_of_Candidate_NEW: 'State of Candidate',
  Interested_Info_Date: 'Interested Info Date',
  Lead_Month_Date_auto: 'Lead Month Date Auto',
  Date_of_processing: 'Date of processing - Conversion',
  English_First_Name: 'English First Name',
  English_Last_Name: 'English Last Name',
  First_Name: 'First Name',
  Last_Name: 'Last Name',
  Full_Name: 'Full Name',
  English_level: 'English Level',
  Seniority_Level: 'Seniority Level',
  Seniority_Start_Date: 'Seniority Start Date',
  TL_intro_date: 'TL Intro Date',
  Prescreen_failed_because: 'Prescreen Failing Reason',
  Primary_Skill_Set: 'Primary Skills',
  Secondary_Skill_Set: 'Secondary Skills',
  Skill_Set: 'Skills',
  Full_Stack: 'Full Stack',
  Type_of_Developer: 'Type of Developer',
  Date_of_Prescreen: 'Date of Intro Done',
  Date_of_Prescreen_Planned: 'Date of Intro Planned',
  Date_of_Tech_Interview_planned: 'Date of Tech Interview Planned',
  Date_of_Submission: 'Date of Submission',
  Date_of_last_apply: 'Date of Last Apply',
  Location_City: 'Location City',
  Location_Country: 'Location Country',
  New_Location: 'Location',
  Location_Area: 'Location Area',
  Time_zone: 'Time Zone',
  'Dev_-_QA-AQA': 'Specialization',
  Number_of_suitable_positions: 'Number of suitable positions',
  Hourly_Rate_Expected: 'Hourly Rate Expected',
  Source: 'Source',
  Leads_owner_2018: 'Responsible Recruiter',
  Recruiters_Feedback: 'Recruiters Feedback',
  Talked_with: 'Talked with',
  Clients_Submission: 'Clients Submission',
  Date_of_Clients_Interview: 'Date of Clients Interview',
  "Client's_Feedback": "Client's Feedback",
  TL_Intro: 'TL intro reviewer',
  TL_intro_feedback: 'TL intro feedback',
  LP_CV_link: 'LP CV link',
  CV_File_Name: 'CV File Name',
  AE_Formatted_CV_Link: 'AE Formatted CV link',
  Type_of_channel: 'Type of Channel',
  Type_of_Inbound_channel: 'Type of Inbound Channel',
  Type_of_Outbound_channel: 'Type of Outbound Channel',
  Interested_in_JO: 'Interested in JO',
  LeadGen_owner: 'LeadGen Owner',
  New_Home_Date_Start: 'New Home Date Start',
  New_Home_Date_End: 'New Home Date End',
  Test_Task_Reviewers: 'Technical Interviewer',
  Interview: 'Technical Interview Status',
  Technical_Conslusion: 'Technical Conclusion',
  Candidate_Status: 'Status',
  LP_match_Positions_Date: 'LP Match Positions Date',
  LP_application_match_Positions_IDs: 'LP Application Match Positions IDs',
  Compensation_Expected: 'Compensation Expected',
  Hourly_Rate_Current: 'Hourly Rate Current',
  Hourly_Rate_Offer1: 'Hourly Rate Offer',
  Compensation_Current: 'Compensation Current',
  Video_Interview_Link: 'Video Interview Link',
  VI_result: 'Video Interview Result',
  VI_feedback: 'Video Interview Feedback',
  Updated_Date: 'Updated Date',
  Leads_reply_Date: 'Lead assigning date',
  Calendly_calendar: 'Calendly calendar',
  Stage_of_closing: 'Stage of Closing',
  Replied_to_template: 'Lead Replied to Remplate',
  Brand_replied_Interested: 'Brand replied Interested',
  VI_date: 'VI date',
  Submitted_to_client: 'Submitted to Client',
  Clients_Interview: 'Clients Interview',
  Final_decision_from_client: 'Final Decision from Client',
  VI_sent_date: 'Video Interview Sent Date',
  Date_of_offer_done: 'Offer Sent Date',
  SOW_signed_date: 'SOW Signed Date',
  Current_Company: 'Current Company',
  Current_employment_type: 'Current Employment Type',
  Expected_employment_type: 'Expected Employment Type',
  Stage: 'Stage',
  score: 'Score',
  matchedJobOpenings: 'Matched Job Openings',
  actionReport: 'ActionReport',
  LP_application_Date: 'LP Application Date',
  Added_by: 'Added by',
  Interested_Info_Owner: 'Interested Infro Owner',
  Nickname_Launch_Pod: 'Nickname',
  Location_Grabb: 'Location Grabb',
  Company_do_not_touch: 'Company do not touch',
  Do_not_touch_Russia: 'Do not touch Russia',
  TI_Suggested_Seniority: 'TI Suggested Seniority',
  Phone: 'Phone',
  Telegram: 'Telegram',
  'Facebook_/_VK_URL': 'Facebook',
  Instagram: 'Instagram',
  Twitter: 'Twitter',
  Whatsapp: 'Whatsapp',
  Candidate_Status_Updated_At: 'Candidate Status Updated at',
  Self_gen_Touch_Date: 'Self-gen Touch Date',
  Hot_Pipeline: 'Hot Pipeline',
  Hot_Pipeline_Start_Date: 'Hot Pipeline Start Date',
  Hot_Pipeline_End_Date: 'Hot Pipeline End Date',
  Djinni_URL: 'Djinni URL',
  GitHub_Link: 'GitHub Link',
  LP_Invitation_Sent: 'LP Invitation Sent',
  Date_of_Submission_Reply: 'Date of Submission Reply',
  Internal_referrer_Email: 'Internal referrer Email',
  Referral_Date: 'Referral Date',
};

export const CandidateDetailEmptyValues = ['-None-', '----'];
