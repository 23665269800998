import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { useFormik } from 'formik';
import { get, isArray, isEqual, set } from 'lodash';

import { Container } from '@mui/material';
import { LoaderSkeleton, ForbiddenView } from '@components';
import { JobOpeningDetails } from './components/JobOpeningDetails';
import { SpecializationDetails } from './components/SpecializationDetails';
import { JobDescription } from './components/JobDescription';
import { HiringProcessDetails } from './components/HiringProcessDetails';
import { LocationDetails } from './components/LocationDetails';
import { Footer } from './components/Footer';

import { authSelectors } from '@redux/auth';
import {
  createNewOpenPosition,
  editOpenPosition,
  fetchJobOpeningById,
  jobOpeningDetailsSelectors,
} from '@redux/jobOpeningDetails';
import { optionsSelectors } from '@redux/options';

import {
  IJobOpeningCrucialRequirement,
  JobOpeningFormValues,
  JOTestTaskForm,
} from '@types';
import { AppRoutes, JobOpeningFormMode } from '@constants';

import {
  getJobOpeningFormInitialValues,
  getSystemFieldOnJobCreate,
  getSystemFieldOnJobEdit,
  checkUserCanOpenEditJobOpening,
  isStatusLoading,
  isStatusIdle,
} from '@utils';

import { validationSchema } from './validationSchema';

export const JobOpeningForm: React.FC<{
  mode: JobOpeningFormMode;
}> = ({ mode }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const profile = useAppSelector(authSelectors.getProfileData);
  const jobOpeningId = useAppSelector(
    jobOpeningDetailsSelectors.getJobOpeningDetailsId,
  );
  const jobOpeningData = useAppSelector(
    jobOpeningDetailsSelectors.getJobOpeningDetailsData,
  );
  const joAPIStatus = useAppSelector(
    jobOpeningDetailsSelectors.getJobOpeningDetailsApiStatus,
  );
  const isFormSubmitting = useAppSelector(
    jobOpeningDetailsSelectors.getJobOpeningIsPerformingAction,
  );
  const { data: countries } = useAppSelector(
    optionsSelectors.getJobOpeningCountries,
  );
  const { data: clients } = useAppSelector(
    optionsSelectors.getJobOpeningClients,
  );
  const { data: specializations } = useAppSelector(
    optionsSelectors.getJobOpeningSpecializations,
  );

  const isAbleToCreateUpdateJO = checkUserCanOpenEditJobOpening(profile);

  const onSuccess = useCallback(
    () => navigate(`/${AppRoutes.JOB_OPENINGS}`),
    [],
  );

  useEffect(() => {
    if (jobOpeningId && isStatusIdle(joAPIStatus)) {
      dispatch(fetchJobOpeningById(jobOpeningId));
    }
  }, [jobOpeningId, joAPIStatus]);

  const initialValues = getJobOpeningFormInitialValues(profile, jobOpeningData);

  const formik = useFormik<JobOpeningFormValues>({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      if (
        mode === JobOpeningFormMode.Create ||
        mode === JobOpeningFormMode.Duplicate
      ) {
        const systemFields = getSystemFieldOnJobCreate(
          values,
          clients,
          specializations,
        );

        await dispatch(
          createNewOpenPosition({
            data: { ...values, ...systemFields },
            onSuccess,
          }),
        );
      } else {
        const resultObject: Partial<JobOpeningFormValues> = {};

        Object.entries(initialValues)?.map((entry) => {
          const [key, oldVal] = entry;
          const newVal = get(values, key);

          if (key === 'crucialRequirements') {
            const newReqs = (newVal as IJobOpeningCrucialRequirement[]).map(
              (e) => `${e.name}-${e.priority}`,
            );
            const oldReqs = (oldVal as IJobOpeningCrucialRequirement[]).map(
              (e) => `${e.name}-${e.priority}`,
            );
            !isEqual([...newReqs].sort(), [...oldReqs].sort()) &&
              set(resultObject, key, newVal);
          } else if (key === 'testTask') {
            const newTestTasks = (newVal as JOTestTaskForm[]).map(
              (e) => `${e.technicalFlow}-${e.type}`,
            );
            const oldTestTasks = (oldVal as JOTestTaskForm[]).map(
              (e) => `${e.technicalFlow}-${e.type}`,
            );
            !isEqual([...newTestTasks].sort(), [...oldTestTasks].sort()) &&
              set(resultObject, key, newVal);
          } else if (isArray(newVal) && isArray(oldVal)) {
            !isEqual([...newVal].sort(), [...oldVal].sort()) &&
              set(resultObject, key, newVal);
          } else {
            newVal !== oldVal && set(resultObject, key, newVal);
          }
        });

        const systemFields = getSystemFieldOnJobEdit(
          resultObject,
          jobOpeningData!,
          countries,
          clients,
          specializations,
        );

        await dispatch(
          editOpenPosition({
            id: jobOpeningData?.id || '',
            data: { ...resultObject, ...systemFields },
            onSuccess,
          }),
        );
      }
    },
    validateOnMount: true,
    enableReinitialize: true,
  });

  if (!isAbleToCreateUpdateJO) {
    return <ForbiddenView />;
  }

  if (isStatusLoading(joAPIStatus)) {
    return <LoaderSkeleton />;
  }

  console.log(
    formik.values.testTasks,
    formik.errors.testTasks,
    formik.touched.testTasks,
  );

  return (
    <Container maxWidth="lg">
      <JobOpeningDetails formik={formik} mode={mode} />
      <LocationDetails formik={formik} />
      <SpecializationDetails formik={formik} />
      <JobDescription formik={formik} />
      <HiringProcessDetails formik={formik} />
      <Footer formik={formik} isFormSubmitting={isFormSubmitting} mode={mode} />
    </Container>
  );
};
