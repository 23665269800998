import { CandidateStage, CandidateStagesOrder } from '@constants';
import { IDWHCandidate } from '@types';
import { isCandidateNewHomeStage } from '@utils';

export const isCandidatePassedStage = (
  candidate: IDWHCandidate,
  stage: CandidateStage,
) =>
  CandidateStagesOrder.indexOf(candidate.candidateStage) >=
  CandidateStagesOrder.indexOf(stage);

export const getCandidatesByStage = (
  candidates: IDWHCandidate[],
  stage: CandidateStage,
) => {
  switch (stage) {
    case CandidateStage.NEW_HOME:
      return candidates.filter((candidate) =>
        isCandidateNewHomeStage(candidate),
      );
    case CandidateStage.IC_WAITLIST:
      return candidates.filter(
        (candidate) => candidate.candidateStage === CandidateStage.IC_WAITLIST,
      );
    default:
      return candidates.filter(
        (candidate) =>
          candidate.candidateStage !== CandidateStage.IC_WAITLIST &&
          isCandidatePassedStage(candidate, stage),
      );
  }
};
