import React, { useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '@redux/hooks';

import { Checkbox, FormControlLabel, Grid, IconButton } from '@mui/material';
import AddToQueueIcon from '@mui/icons-material/AddToQueue';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { AutocompleteTextfield, CustomButton, Flex } from '@components';
import { VideoInterviewQuestions } from './components/VIQuestions';

import { fetchVideoInterviewListAll, optionsSelectors } from '@redux/options';

import { isStatusIdle } from '@utils';
import { VISpecialization } from '@constants';
import { JOVideoInterviewForm, VideoInterviewQuestion } from '@types';

const OptionalVISpecializations = [
  VISpecialization.DISABLED,
  'Developer',
  'AQA',
  'QA',
  'Data Engineer',
  'DevOps',
  'UX Researcher',
  'UI/UX Designer',
];

export const PositionVideoInterviewSelect: React.FC<{
  value: JOVideoInterviewForm[];
  selectedLocations: string[];
  onChange: (newValue: JOVideoInterviewForm[]) => void;
}> = ({ value, selectedLocations, onChange }) => {
  const dispatch = useAppDispatch();

  const { apiStatus, data } = useAppSelector(
    optionsSelectors.getAllVideoInterviewList,
  );

  useEffect(() => {
    if (isStatusIdle(apiStatus)) {
      dispatch(fetchVideoInterviewListAll());
    }
  }, [apiStatus, dispatch]);

  const handleAddVideoInterview = () => {
    onChange([
      ...value,
      {
        specialization: null,
        locations: null,
        isCustom: false,
        isRequired: false,
        isDisabled: false,
        questions: [],
      },
    ]);
  };

  const handleRemoveVideoInterview = (removeIndex: number) => {
    onChange(value.filter((_, idx) => idx !== removeIndex));
  };

  const handleSpecializationChange = (
    changeIndex: number,
    specialization: string,
  ) => {
    const selectedVI = data.find((e) => e.specialization === specialization);

    onChange(
      value.map((e, idx) =>
        idx === changeIndex
          ? {
              specialization:
                specialization === VISpecialization.DISABLED
                  ? null
                  : specialization,
              locations: null,
              isCustom: false,
              isDisabled: specialization === VISpecialization.DISABLED,
              isRequired: !OptionalVISpecializations.includes(specialization),
              questions: selectedVI?.questions || [],
            }
          : e,
      ),
    );
  };

  const handleRequiredChange = (changeIndex: number, isRequired: boolean) => {
    onChange(
      value.map((e, idx) =>
        idx === changeIndex
          ? {
              ...e,
              isRequired,
            }
          : e,
      ),
    );
  };

  const handleLocationsChange = (changeIndex: number, locations: string[]) => {
    const nextLocations =
      locations.at(-1) === 'All locations'
        ? []
        : locations.filter((location) => location !== 'All locations');

    onChange(
      value.map((e, idx) =>
        idx === changeIndex
          ? {
              ...e,
              locations: !nextLocations.length ? null : nextLocations,
            }
          : e,
      ),
    );
  };

  const handleQuestionsChange = (
    changeIndex: number,
    questions: VideoInterviewQuestion[],
  ) => {
    onChange(
      value.map((e, idx) => {
        const initialQuestions =
          data.find((vi) => vi.specialization === e.specialization)
            ?.questions || [];

        return idx === changeIndex
          ? {
              ...e,
              isCustom:
                JSON.stringify(questions) !== JSON.stringify(initialQuestions),
              questions: questions,
            }
          : e;
      }),
    );
  };
  const specializationOptions = useMemo(() => {
    return [VISpecialization.DISABLED, ...data.map((e) => e.specialization)];
  }, [data]);

  const locationOptions = useMemo(() => {
    const viLocations = value.flatMap((vi) => vi.locations);

    return [
      'All locations',
      ...selectedLocations.filter(
        (location) => !viLocations.includes(location),
      ),
    ];
  }, [value, selectedLocations]);

  return (
    <Flex flexDirection="column" gap={3} width="100%" alignItems="start">
      {value.map((vi, idx) => (
        <Flex
          flexDirection="column"
          gap={2}
          alignItems="start"
          key={`${vi.specialization}-${idx}`}
          width="100%"
        >
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs={12} md={5}>
              <AutocompleteTextfield
                label={'Async video interview questions'}
                options={specializationOptions}
                value={
                  vi.isDisabled ? VISpecialization.DISABLED : vi.specialization
                }
                disableClearable
                onChange={(_, val: string) => {
                  handleSpecializationChange(idx, val);
                }}
                getOptionLabel={(option) =>
                  vi.isCustom && option === vi.specialization
                    ? `${option} (Custom)`
                    : option
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <AutocompleteTextfield
                label="Locations"
                options={locationOptions}
                value={vi.locations || ['All locations']}
                multiple
                filterSelectedOptions
                disableClearable
                onChange={(_, val: string[]) => {
                  handleLocationsChange(idx, val);
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={vi.isRequired}
                    onChange={(_, val) => handleRequiredChange(idx, val)}
                  />
                }
                label="Required"
              />
            </Grid>
            {value.length > 1 && (
              <Grid item xs={12} md={1}>
                <IconButton onClick={() => handleRemoveVideoInterview(idx)}>
                  <DeleteOutlineOutlinedIcon />
                </IconButton>
              </Grid>
            )}
          </Grid>
          <VideoInterviewQuestions
            questions={vi.questions}
            onChange={(val) => handleQuestionsChange(idx, val)}
          />
        </Flex>
      ))}
      <CustomButton
        size="small"
        color="secondary"
        label="Add another VI"
        startIcon={<AddToQueueIcon fontSize="small" />}
        disabled={value.some((e) => !e.specialization)}
        onClick={() => handleAddVideoInterview()}
      />
    </Flex>
  );
};
