import {
  CandidateStage,
  CandidateStatus,
  CandidateUpcomingEvents,
} from '@constants';
import {
  ICandidateByStage,
  IDWHCandidate,
  IDWHCandidateRaw,
  IZohoCandidate,
  OrderDirection,
} from '@types';
import { checkDateIsAfter } from '@utils';
import _ from 'lodash';
import { startCase } from 'lodash';
import { LocalStorageService } from 'services/localStorageService';
const parseField = (field: string | null) =>
  !field
    ? []
    : field.startsWith('[') && field.endsWith(']')
    ? JSON.parse(field)
    : field
        .replace(/[{}]/g, '')
        .split(/[,;]+/)
        .map((e: string) => e.trim());

export const transformCandidate = (data: IDWHCandidateRaw): IDWHCandidate => {
  const parsedWasInterestedMonth = parseField(data.Was_interested_month);
  const parsedTestTaskStatus = parseField(data.Test_Task);
  const parsedPrimarySkills = parseField(data.Primary_Skill_Set);
  const parsedSecondarySkills = parseField(data.Secondary_Skill_Set);
  const parsedTestTaskReviewers = parseField(data.Test_Task_Reviewers);

  return {
    ...data,
    Was_interested_month: parsedWasInterestedMonth,
    Skill_Set: (data.Skill_Set || '')
      .split(/[,;]+/)
      .map((e: string) => e.trim())
      .filter(Boolean),
    Test_Task: parsedTestTaskStatus.filter(Boolean),
    Primary_Skill_Set: parsedPrimarySkills,
    Secondary_Skill_Set: parsedSecondarySkills,
    Test_Task_Reviewers: parsedTestTaskReviewers,
  };
};

export const getCandidateName = ({
  fullName,
  firstName,
  lastName,
  englishFirstName,
  englishLastName,
}: {
  fullName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  englishFirstName?: string | null;
  englishLastName?: string | null;
}): string => {
  if (
    !(fullName || firstName || lastName || englishFirstName || englishLastName)
  )
    return '';

  const fullNameToUse = fullName?.trim();
  if (fullNameToUse) return fullNameToUse;

  const firstNameToUse = firstName?.trim() || englishFirstName?.trim();
  const lastNameToUse = lastName?.trim() || englishLastName?.trim();

  if (firstNameToUse || lastNameToUse)
    return `${firstNameToUse} ${lastNameToUse}`;

  return '';
};

export const getNameFromEmail = (
  email?: string | null,
): { firstName: string; lastName: string } | null => {
  if (!email) return null;

  const emailPrefix = email.split('@')[0];

  const nameParts = emailPrefix.split('.');

  const firstName = nameParts[0];
  const lastName = nameParts[nameParts.length - 1];

  return {
    firstName: startCase(firstName),
    lastName: startCase(lastName),
  };
};

export const validateEmail = (email: string) => {
  return email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};

export const transormCandidateStatus = (
  status: string | null | undefined,
): string => (!status ? '' : status.replace(/LP: /g, ''));

export const formatEmailMessage = (rawMessage: string) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  let formattedMessage = rawMessage.replace(urlRegex, (url: string) => {
    if (url.includes('calendly.com/')) {
      LocalStorageService.setState('user_calendly_link', url);
    }
    return `<a style="color: #3F8CFF; text-decoration: underline;" href="${url}" target="_blank">${url}</a>`;
  });
  formattedMessage = formattedMessage.replace(/\n/g, '<br/>');
  return formattedMessage;
};

const getStageDate = (
  candidate: IDWHCandidate,
  stage: CandidateStage | CandidateUpcomingEvents,
): string | null => {
  const map: Record<CandidateStage | CandidateUpcomingEvents, string | null> = {
    [CandidateStage.APPLIED]:
      candidate.Date_Of_Last_Apply || candidate.Interested_Info_Date || null,
    [CandidateStage.TT_PASSED]: candidate.Test_Task_Date || null,
    [CandidateStage.IC_SCHEDULED]: candidate.Date_of_Prescreen_Planned || null,
    [CandidateStage.IC_DONE]: candidate.Date_Of_Prescreen || null,
    [CandidateStage.IC_PASSED]: candidate.Date_Of_Prescreen || null,
    [CandidateStage.TI_PASSED]:
      candidate.associationStatuses?.find((sc) =>
        [
          CandidateStatus.TI_Passed,
          CandidateStatus.TI_Passed_Lower_Seniority,
        ].includes(sc.status),
      )?.date ||
      candidate.Date_Of_Tech_Interview_Planned ||
      null,
    [CandidateStage.SUBMITTED]:
      candidate.associationStatuses?.find((sc) =>
        [CandidateStatus.Submitted].includes(sc.status),
      )?.date ||
      candidate.Date_Of_Submission ||
      null,
    [CandidateStage.NEW_HOME]: candidate.New_Home_Date_Start || null,
    [CandidateStage.IC_WAITLIST]:
      candidate.associationStatuses?.find((sc) =>
        [CandidateStatus.IC_Waitlist].includes(sc.status),
      )?.date || null,
    [CandidateUpcomingEvents.IC]: candidate.Date_of_Prescreen_Planned || null,
    [CandidateUpcomingEvents.TI]:
      candidate.Date_Of_Tech_Interview_Planned || null,
  };

  return map[stage];
};

export const transformDWHCandidateToCandidatesByStage = (
  candidate: IDWHCandidate,
  stage: CandidateStage | CandidateUpcomingEvents,
): ICandidateByStage => {
  return {
    id: candidate.id,
    fullName: candidate.Full_Name,
    firstName: candidate.First_Name,
    lastName: candidate.Last_Name,
    englishFirstName: candidate.English_First_Name,
    englishLastName: candidate.English_Last_Name,
    seniority: candidate.Seniority_Level,
    technicalFlow: candidate.Technical_Flow,
    country: candidate.Location_Country,
    date: getStageDate(candidate, stage),
    status: candidate.associationStatuses?.at(-1)?.status || null,
    score: candidate.score,
    candidateStatusUpdatedAt:
      candidate.associationStatuses?.at(-1)?.date || null,
  };
};

export const getDateColumnTitleBasedOnStage = (
  stage: CandidateStage | CandidateUpcomingEvents,
): string => {
  const map: Record<CandidateStage | CandidateUpcomingEvents, string> = {
    [CandidateStage.APPLIED]: 'Date of apply',
    [CandidateStage.TT_PASSED]: 'Date of TT passed',
    [CandidateStage.IC_SCHEDULED]: 'Date of IC scheduled',
    [CandidateStage.IC_DONE]: 'Date of IC done',
    [CandidateStage.IC_PASSED]: 'Date of IC passed',
    [CandidateStage.TI_PASSED]: 'Date of TI passed',
    [CandidateStage.SUBMITTED]: 'Date of submission',
    [CandidateStage.NEW_HOME]: 'New Home start date',
    [CandidateStage.IC_WAITLIST]: 'Date of IC waitlist',
    [CandidateUpcomingEvents.IC]: 'Date of upcoming IC',
    [CandidateUpcomingEvents.TI]: 'Date of upcoming TI',
  };

  return map[stage];
};

export const checkCandidateStage = (
  candidate: Record<string, any>,
  stage: CandidateStage,
): boolean => {
  // APPLIED Stage
  if (stage === CandidateStage.APPLIED) {
    if (
      !checkDateIsAfter(
        candidate.Date_Of_Last_Apply,
        candidate.Lead_Month_Date_auto,
      )
    )
      return false;
  }

  // TT_PASSED Stage
  if (stage === CandidateStage.TT_PASSED) {
    const stages = candidate.Test_Task;
    if (!stages.includes('Passed')) return false;
  }

  // IC_SCHEDULED Stage
  if (stage === CandidateStage.IC_SCHEDULED) {
    if (
      !checkDateIsAfter(
        candidate.Date_of_Prescreen_Planned,
        candidate.Date_of_processing,
      )
    )
      return false;
  }

  // IC_DONE Stage
  if (stage === CandidateStage.IC_DONE) {
    if (!candidate.Date_Of_Prescreen) return false;
    if (
      !checkDateIsAfter(
        candidate.Date_Of_Prescreen,
        candidate.Date_of_Prescreen_Planned
          ? new Date(candidate.Date_of_Prescreen_Planned)
          : candidate.Date_of_processing,
      )
    )
      return false;
  }

  // IC_PASSED Stage
  if (stage === CandidateStage.IC_PASSED) {
    if (candidate.Prescreen_Failed_Because !== null) return false;
    if (
      !checkDateIsAfter(
        candidate.Date_Of_Prescreen,
        candidate.Lead_Month_Date_auto,
      )
    )
      return false;
  }

  // TI_PASSED Stage
  if (stage === CandidateStage.TI_PASSED) {
    if (!candidate.Ti_Result?.toLowerCase().includes('passed')) return false;
    if (
      !checkDateIsAfter(
        candidate.Date_Of_Tech_Interview_Planned,
        candidate.Lead_Month_Date_auto,
      )
    )
      return false;
  }

  // SUBMITTED Stage
  if (stage === CandidateStage.SUBMITTED) {
    if (
      !checkDateIsAfter(
        candidate.Date_Of_Submission,
        candidate.Lead_Month_Date_auto,
      )
    )
      return false;
  }

  // NEW_HOME Stage
  if (stage === CandidateStage.NEW_HOME) {
    if (
      candidate.Type_of_Inbound_Channel !== 'New Home' ||
      !candidate.New_Home_Date_Start ||
      candidate.New_Home_Date_End !== null
    ) {
      return false;
    }
  }

  return true;
};
// Process sort and filter
export interface IDataProcessor<T, U> {
  filterData: (data: U[], options: Partial<T>) => U[];
  sortData: (
    data: U[],
    sortBy: string | null,
    order: OrderDirection | null,
  ) => U[];
}

export const isCandidateNewHomeStage = (
  candidate: IDWHCandidate | IZohoCandidate,
) => {
  const typeOfInboundChannel =
    (candidate as IDWHCandidate).Type_of_Inbound_Channel ??
    (candidate as IZohoCandidate).Type_of_Inbound_channel;

  return (
    typeOfInboundChannel === 'New Home' &&
    !!candidate.New_Home_Date_Start &&
    !candidate.New_Home_Date_End
  );
};

export const createDataProcessor = <
  T extends Record<string, any>,
  U extends Record<string, any>,
>(): IDataProcessor<T, U> => {
  const filterData = (data: U[], options: Partial<T>): U[] => {
    if (!data || data.length === 0) return [];

    return data.filter((item) => {
      if (options.search) {
        const searchTerm = options.search.toLowerCase().trim();
        const searchableFields = [
          item.Full_Name,
          item.id,
          item.Email,
          item.Email_from_CP,
          item.Second_Email,
          item.Email_from_Linkedin,
        ].filter(Boolean);

        const matchesSearch = searchableFields.some((field) =>
          String(field).toLowerCase().includes(searchTerm),
        );

        if (!matchesSearch) return false;
      }

      if (options.stage) {
        return checkCandidateStage(item, options.stage);
      }

      if (options.state) {
        const itemStatus = item.State_of_Candidate_NEW;

        if (!itemStatus) return false;

        if (!itemStatus.toLowerCase().includes(options.state.toLowerCase()))
          return false;
      }

      if (options.country?.length) {
        const itemCountry = item.Location_Country;
        if (!itemCountry) return false;

        const normalizedCountries = options.country.map((c: string) =>
          c.toLowerCase().includes('usa') ? 'USA' : c,
        );

        const matchesCountry = normalizedCountries.some((c: string) =>
          itemCountry.toLowerCase().includes(c.toLowerCase()),
        );

        if (!matchesCountry) return false;
      }

      if (options.seniority?.length) {
        const itemSeniority = item.Seniority_Level;
        if (!itemSeniority) return false;

        const matchesSeniority = options.seniority.some(
          (s: string) => itemSeniority.toLowerCase() === s.toLowerCase(),
        );

        if (!matchesSeniority) return false;
      }

      if (options.payRate?.length === 2) {
        const rate = item.Hourly_Rate_Expected;
        if (!rate) return false;

        const [min, max] = options.payRate.map(Number);
        if (rate < min || rate > max) return false;
      }

      if (options.recruiter?.length) {
        const itemRecruiter = item.Leads_Owner_2018;
        if (!itemRecruiter) return false;

        const matchesRecruiter = options.recruiter.some(
          (r: string) => itemRecruiter.toLowerCase() === r.toLowerCase(),
        );

        if (!matchesRecruiter) return false;
      }

      if (options.addedBy?.length) {
        const itemAddedBy = item.Added_by;
        if (!itemAddedBy) return false;

        const matchesAddedBy = options.addedBy.some(
          (a: string) => itemAddedBy.toLowerCase() === a.toLowerCase(),
        );

        if (!matchesAddedBy) return false;
      }

      if (options.techFlow?.length) {
        const itemTechFlow = item.Technical_Flow;
        if (!itemTechFlow) return false;

        const matchesTechFlow = options.techFlow.some(
          (t: string) => itemTechFlow.toLowerCase() === t.toLowerCase(),
        );

        if (!matchesTechFlow) return false;
      }

      return true;
    });
  };
  const sortData = (
    data: U[],
    sortBy: string | null,
    order: OrderDirection | null,
  ): U[] => {
    if (!sortBy || !order) return data;

    return _.orderBy(
      data,
      [sortBy],
      [order === OrderDirection.ASC ? 'asc' : 'desc'],
    );
  };
  return {
    filterData,
    sortData,
  };
};
