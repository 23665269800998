import { useCallback, useMemo, useState } from 'react';
import { orderBy as orderByFunc } from 'lodash';

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  Button,
} from '@mui/material';
import { CandidateScore, CountryFlag, Flex } from '@components';
import SouthRoundedIcon from '@mui/icons-material/SouthRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import {
  AppRoutes,
  CandidateDetailsByStageSortingOptions,
  OrderDirection,
} from '@constants';

import { ICandidateByStage } from '@types';
import {
  formatDate,
  getCandidateName,
  isCandidateDisqualified,
  isScoreIncomplete,
  transormCandidateStatus,
} from '@utils';
const CANDIDATES_PER_PAGE = 30;

export const CandidatesDetailsByStage: React.FC<{
  title: string;
  dateColumnTitle: string;
  data: ICandidateByStage[] | null;
  onClick?: (value: null) => void;
}> = ({ title, dateColumnTitle, data, onClick }) => {
  const [order, setOrder] = useState<OrderDirection>(OrderDirection.ASC);
  const [sortBy, setSortBy] = useState<CandidateDetailsByStageSortingOptions>(
    CandidateDetailsByStageSortingOptions.Date,
  );
  const [visibleCandidatesCount, setVisibleCandidatesCount] =
    useState(CANDIDATES_PER_PAGE);

  const openCandidateDetailsInNewTab = (candidateId: string) => {
    window.open(
      `/${AppRoutes.CANDIDATES}/${candidateId}`,
      '_blank',
      'noreferrer',
    );
  };

  const sortedCandidates = useMemo(() => {
    if (data && order && sortBy) {
      return [
        ...orderByFunc(
          data.filter(
            (a) =>
              !isCandidateDisqualified(a.score) && !isScoreIncomplete(a.score),
          ),
          sortBy,
          order,
        ),
        ...orderByFunc(
          data.filter(
            (a) =>
              isCandidateDisqualified(a.score) || isScoreIncomplete(a.score),
          ),
          sortBy,
          order,
        ),
      ];
    }

    return data;
  }, [order, sortBy, data]);

  const handleClick = useCallback(
    (value: CandidateDetailsByStageSortingOptions) => {
      if (value === sortBy) {
        const toggleOrder =
          order === OrderDirection.ASC
            ? OrderDirection.DESC
            : OrderDirection.ASC;
        setOrder(toggleOrder);
      } else {
        setSortBy(value);
        setOrder(OrderDirection.DESC);
      }
    },
    [sortBy, order],
  );

  const handleShowMore = () => {
    setVisibleCandidatesCount((prevCount) => prevCount + CANDIDATES_PER_PAGE);
  };

  if (!data?.length || !sortedCandidates) return null;

  return (
    <Box width="100%">
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        position={'sticky'}
        top={10}
        sx={(theme) => ({
          background: theme.palette.background.brandLight,
        })}
        padding={1}
        mb={1}
        borderRadius={'8px'}
      >
        <Typography color="text.primary" variant="body1">
          {title}
        </Typography>

        <Box
          display={'flex'}
          alignItems={'center'}
          gap={0.5}
          sx={{ cursor: 'pointer' }}
        >
          <Typography color="text.secondary">Close</Typography>

          <CloseRoundedIcon
            onClick={() => onClick && onClick(null)}
            color="secondary"
            fontSize="small"
          />
        </Box>
      </Box>

      <TableContainer sx={{ width: '100%' }}>
        <Table aria-label="Potential candidates" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell
                sx={(theme) => ({
                  background: theme.palette.background.backgroundPrimary,
                  width: '30%',
                  borderRadius: '8px 0 0 8px',
                  borderBottom: 'none',
                  padding: '8px',
                })}
                align="left"
              >
                Full name
              </TableCell>
              <TableCell
                sx={(theme) => ({
                  background: theme.palette.background.backgroundPrimary,
                  borderBottom: 'none',
                  padding: '8px',
                  width: '20%',
                  whiteSpace: 'nowrap',
                })}
                align="left"
              >
                <TableSortLabel
                  direction={order}
                  active={sortBy == CandidateDetailsByStageSortingOptions.Date}
                  onClick={() =>
                    handleClick(CandidateDetailsByStageSortingOptions.Date)
                  }
                  sx={{
                    color: 'inherit',
                    '&.Mui-active': {
                      color: 'rgba(0, 0, 0, 0.60)',
                      '& .MuiTableSortLabel-icon': {
                        color: 'rgba(0, 0, 0, 0.60)',
                      },
                    },
                  }}
                >
                  {dateColumnTitle}
                </TableSortLabel>
              </TableCell>
              <TableCell
                align="left"
                sx={(theme) => ({
                  background: theme.palette.background.backgroundPrimary,
                  borderBottom: 'none',
                  padding: '8px',
                  width: '40%',
                })}
              >
                Current status
              </TableCell>
              <TableCell
                sx={(theme) => ({
                  background: theme.palette.background.backgroundPrimary,
                  borderRadius: '0 8px 8px 0',
                  borderBottom: 'none',
                  padding: '8px',
                  width: '10%',
                })}
                align="left"
              >
                <TableSortLabel
                  direction={order}
                  active={sortBy == CandidateDetailsByStageSortingOptions.Score}
                  onClick={() =>
                    handleClick(CandidateDetailsByStageSortingOptions.Score)
                  }
                >
                  Score
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedCandidates
              ?.slice(0, visibleCandidatesCount)
              .map((candidate) => {
                return (
                  <TableRow
                    key={candidate.id}
                    sx={(theme) => ({
                      cursor: 'pointer',
                      '&:last-child td, &:last-child th': {
                        border: 0,
                      },
                      '&:hover': {
                        background: theme.palette.highlight.actionable,
                      },
                    })}
                    onClick={() => openCandidateDetailsInNewTab(candidate.id)}
                  >
                    <TableCell
                      sx={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        padding: '5px',
                      }}
                    >
                      <Flex gap={1} alignItems="start">
                        <CountryFlag country={candidate.country} />
                        <Flex flexDirection="column" alignItems="start">
                          <Typography variant="body1" color="text.primary">
                            {getCandidateName({
                              fullName: candidate.fullName,
                              firstName: candidate.firstName,
                              lastName: candidate.lastName,
                              englishFirstName: candidate.englishFirstName,
                              englishLastName: candidate.englishLastName,
                            })}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            {candidate.seniority} {candidate.technicalFlow}
                          </Typography>
                        </Flex>
                      </Flex>
                    </TableCell>
                    <TableCell
                      sx={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        padding: '8px',
                      }}
                      align="left"
                    >
                      <Flex flexDirection="column" alignItems="start">
                        <Typography>
                          {candidate?.date ? formatDate(candidate?.date) : '-'}
                        </Typography>
                      </Flex>
                    </TableCell>
                    <TableCell
                      sx={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        padding: '8px',
                      }}
                      align="left"
                    >
                      <Flex flexDirection="column" alignItems="start">
                        {transormCandidateStatus(candidate.status) || '-'}
                        <Typography variant="body2" color="text.secondary">
                          {candidate?.date
                            ? formatDate(candidate.candidateStatusUpdatedAt)
                            : '-'}
                        </Typography>
                      </Flex>
                    </TableCell>

                    <TableCell align="left" sx={{ padding: '8px' }}>
                      <CandidateScore score={candidate.score} />
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {visibleCandidatesCount < sortedCandidates.length && (
        <Button
          variant="text"
          sx={{
            justifyContent: 'end',
            color: 'text.link',
            fontWeight: 400,
            textDecoration: 'underline',
            textTransform: 'unset',
            fontSize: '0.8125rem',
            ml: -1,
          }}
          onClick={handleShowMore}
        >
          Show more
          <SouthRoundedIcon fontSize="inherit" />
        </Button>
      )}
    </Box>
  );
};
