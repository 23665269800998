import { useState } from 'react';

import { Flex } from '@components';
import { EmptyFeedbackState } from './components/EmptyFeedbackState';
import { ProvidedFeedbackState } from './components/ProvidedFeedbackState';
import { VideoInterviewReviewModal } from './components/VideoInterviewReviewModal';

import { CandidateVideoInterview, ReviewVideoInterviewPayload } from '@types';
import { Typography } from '@mui/material';

export const VideoInterviewFeedback: React.FC<{
  candidateFirstName: string;
  isPerformingVideoInterviewAction: boolean;
  videoInterviewData: CandidateVideoInterview | null;
  postVideoInterviewReview: (data: ReviewVideoInterviewPayload) => void;
}> = ({
  candidateFirstName,
  isPerformingVideoInterviewAction,
  videoInterviewData,
  postVideoInterviewReview,
}) => {
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);

  if (!videoInterviewData) return null;

  return (
    <>
      <Flex flexDirection="column" alignItems="start" gap={2} width="100%">
        <Typography variant="h5">Feedback</Typography>
        {videoInterviewData.reviewer ? (
          <ProvidedFeedbackState
            videoInterviewData={videoInterviewData}
            onEditFeedbackClick={() => setShowFeedbackModal(true)}
          />
        ) : (
          <EmptyFeedbackState
            onProvideFeedbackClick={() => setShowFeedbackModal(true)}
          />
        )}
      </Flex>
      <VideoInterviewReviewModal
        isOpen={showFeedbackModal}
        candidateFirstName={candidateFirstName}
        videoInterviewData={videoInterviewData}
        isLoading={isPerformingVideoInterviewAction}
        handleClose={() => setShowFeedbackModal(false)}
        handleSubmit={postVideoInterviewReview}
      />
    </>
  );
};
