import { Box, Button, Chip, Stack, Tooltip, Typography } from '@mui/material';
import { useState } from 'react';
import PhotoSizeSelectSmallOutlinedIcon from '@mui/icons-material/PhotoSizeSelectSmallOutlined';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import { VIQuestionsList } from '@components';
import { VideoInterviewQuestion } from '@types';
import { VISpecialization } from '@constants';

export const VideoInterviewQuestionPreview: React.FC<{
  videoInterviewQuestionsList: VideoInterviewQuestion[] | null;
  specialization: string | null;
  locations: string[] | null;
  isCustom?: boolean;
  isDisabled?: boolean;
  isRequired?: boolean;
}> = ({
  videoInterviewQuestionsList,
  specialization,
  locations,
  isCustom,
  isDisabled,
  isRequired,
}) => {
  const [isQuestionVisible, setIsQuestionVisible] = useState(false);

  const toggleQuestionVisibility = () => {
    setIsQuestionVisible((prev) => !prev);
  };
  return (
    <>
      <Stack
        sx={{
          padding: '3px 4px 3px 4px',
          flexDirection: {
            xs: 'column',
            sm: 'row',
          },
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
        }}
      >
        <Box
          sx={{
            width: {
              md: '7.5rem',
            },
            marginTop: { sm: '0.25rem' },
            display: 'flex',
            alignItems: 'flex-start',
            gap: '5px',
            marginRight: '8px',
          }}
        >
          <Typography variant="body2" color="text.secondary">
            VI: {locations?.length ? locations.join(', ') : 'All locations'}
          </Typography>
        </Box>

        <Stack
          flex={1}
          direction="row"
          sx={{
            overflowWrap: 'anywhere',
            alignItems: 'flex-start',
            marginTop: { sm: '0.25rem' },
          }}
        >
          <Tooltip title={isRequired ? 'Required' : 'Optional'}>
            <Chip
              label={
                <>
                  {isDisabled ? VISpecialization.DISABLED : specialization}
                  {isCustom ? ' (custom) ' : ''}
                  {isRequired && (
                    <Box
                      component="span"
                      sx={(theme) => ({
                        color: theme.palette.highlight.purple,
                        fontWeight: 500,
                        fontSize: 18,
                      })}
                    >
                      *
                    </Box>
                  )}
                </>
              }
              sx={(theme) => ({
                background: isRequired
                  ? theme.palette.highlight.lightPurple
                  : theme.palette.highlight.lowSeverity,
              })}
            />
          </Tooltip>
        </Stack>

        {videoInterviewQuestionsList && (
          <Button
            variant="text"
            startIcon={
              isQuestionVisible ? (
                <PhotoSizeSelectSmallOutlinedIcon fontSize="small" />
              ) : (
                <ListOutlinedIcon fontSize="small" />
              )
            }
            sx={{
              justifyContent: 'end',
              color: 'text.link',
              fontWeight: 400,
              textTransform: 'unset',
              fontSize: '0.8125rem',
              ml: -1,
              sm: { mt: 1 },
            }}
            onClick={toggleQuestionVisibility}
          >
            {isQuestionVisible ? 'Hide Questions' : 'Show Questions'}
          </Button>
        )}
      </Stack>

      <Stack
        sx={{
          borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
          '&:last-child': {
            borderBottom: 'none',
          },
          gap: {
            xs: '0',
            sm: '0.5rem',
          },
        }}
      >
        {isQuestionVisible && (
          <VIQuestionsList questionsList={videoInterviewQuestionsList || []} />
        )}
      </Stack>
    </>
  );
};
