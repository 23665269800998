import { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getIn } from 'formik';
import { flatten, isEqual, uniq } from 'lodash';

import {
  Box,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  TextField,
  Tooltip,
  Typography,
  createFilterOptions,
} from '@mui/material';
import MailOutlineRoundedIcon from '@mui/icons-material/MailOutlineRounded';
import { AutocompleteTextfield, CustomButton } from '@components';

import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { fetchClientsForSubmission, optionsSelectors } from '@redux/options';
import { submissionSelectors } from '@redux/submission/submission.selectors';
import {
  generateSubmissionEmailIntro,
  generateSubmissionEmailSubject,
  formatJobOpeningName,
  isTruthy,
  isStatusIdle,
  validateEmail,
  isStandaloneRecepient,
  getDefaultSubmissionCCValues,
} from '@utils';
import {
  SearchParams,
  defaultCCOptionsRecipients,
  newHomeTOMRecipient,
} from '@constants';
import { Person, SubmissionFormik } from '@types';

export const SubmissionRecipientsStep: React.FC<{
  formik: SubmissionFormik;
}> = ({ formik }) => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const [
    isNotifyOnceConfirmationDialogOpen,
    setNotifyOnceConfirmationDialogOpen,
  ] = useState<boolean>(false);

  const { data: jobOpenings } = useAppSelector(
    optionsSelectors.getJobOpeningsOptions,
  );

  const { data: candidateDetails } = useAppSelector(
    submissionSelectors.getSubmissionCandidateDetails,
  );
  const { data: submissionClients, apiStatus: clientsAPIStatus } =
    useAppSelector(optionsSelectors.getSubmissionClients);

  const submissionJobOpeningId = searchParams.get(
    SearchParams.SUBMISSION_JOBOPENING_ID,
  );

  useEffect(() => {
    if (isStatusIdle(clientsAPIStatus)) {
      dispatch(fetchClientsForSubmission());
    }
  }, []);

  const clientOptions = useMemo(
    () => uniq(jobOpenings.map((jo) => jo.Client_Name)),
    [jobOpenings],
  );
  const jobOpeningOptions: string[] = useMemo(
    () =>
      jobOpenings
        .filter((jo) => jo.Client_Name === formik.values.clientName)
        .map((jo) => jo.id),
    [jobOpenings, formik.values.clientName],
  );

  // Autoselect the position when redirect from position tab
  useEffect(() => {
    const joToSelect = jobOpenings.find(
      (jo) => jo.id === submissionJobOpeningId,
    );

    if (joToSelect && !formik.values.positions?.length) {
      const submissionJO = {
        id: joToSelect.id,
        jobId: joToSelect.Job_Opening_Id,
        name: joToSelect.Job_Opening_Name,
        client: joToSelect.Client_Name,
      };
      formik.setValues({
        ...formik.values,
        positions: [submissionJO],
        clientName: submissionJO.client,
        email: {
          subject: generateSubmissionEmailSubject(formik.values),
          content: generateSubmissionEmailIntro(formik.values),
        },
      });
    }
  }, [jobOpenings]);

  const selectedJobOpenings = jobOpenings.filter((jo) =>
    formik.values.positions.some((position) => position.id === jo.id),
  );
  const recipientOptions = submissionClients
    .filter(
      (e) =>
        e.company === formik.values.clientName &&
        (!e.teams?.length ||
          selectedJobOpenings.some(
            (jo) => jo.Subteam_Name && e.teams?.includes(jo.Subteam_Name),
          )),
    )
    .map((e) => e);

  const ccOptions = uniq(
    flatten(
      selectedJobOpenings.map((jo) => [
        jo.Hiring_Manager_email,
        jo.PDM,
        jo.Delivery_Manager,
      ]),
    ),
  ).filter(isTruthy);

  useEffect(() => {
    const currentValues = formik.values.cc || [];
    const updatedCCValues = getDefaultSubmissionCCValues(
      currentValues,
      defaultCCOptionsRecipients,
      newHomeTOMRecipient,
      candidateDetails,
    );

    if (!isEqual(updatedCCValues, currentValues)) {
      formik.setFieldValue('cc', updatedCCValues);
    }
  }, [
    formik.values.cc,
    defaultCCOptionsRecipients,
    newHomeTOMRecipient,
    candidateDetails,
    formik.setFieldValue,
  ]);

  const handleConfirmationDialogClose = () => {
    setNotifyOnceConfirmationDialogOpen(false);
  };

  return (
    <>
      <Box display="flex" flexDirection="column" gap={2} py={2}>
        <Typography variant="h3">Select client and recipients</Typography>
        <AutocompleteTextfield
          label="Client"
          options={clientOptions}
          fullWidth
          value={formik.values.clientName}
          required
          onChange={(_, val) => {
            formik.setValues({
              ...formik.values,
              clientName: val,
              positions: [],
              email: {
                subject: null,
                content: null,
              },
              recipients: [],
            });
          }}
          onBlur={() => formik.setFieldTouched('clientName')}
          helperText={
            !!getIn(formik.touched, 'clientName') || !!formik.submitCount
              ? getIn(formik.errors, 'clientName')
              : undefined
          }
          error={
            (!!getIn(formik.touched, 'clientName') || !!formik.submitCount) &&
            !!getIn(formik.errors, 'clientName')
          }
        />
        <AutocompleteTextfield
          label="Job opening"
          options={jobOpeningOptions}
          getOptionLabel={(option) => {
            const jo = jobOpenings.find((position) => position.id === option);

            return formatJobOpeningName(
              jo?.Job_Opening_Name,
              jo?.Job_Opening_Id,
              jo?.Candidates_Number,
            );
          }}
          fullWidth
          value={formik.values.positions.map((e) => e.id)}
          required
          multiple
          filterSelectedOptions
          onChange={(_, val) => {
            const submissionJOs = jobOpenings
              .filter((jo) => val.includes(jo.id))
              .map((selectedJO) => ({
                id: selectedJO.id,
                jobId: selectedJO.Job_Opening_Id,
                name: selectedJO.Job_Opening_Name,
                client: selectedJO.Client_Name,
              }));

            formik.setValues({
              ...formik.values,
              positions: submissionJOs,
              recipients: [],
              email: {
                subject: submissionJOs.length
                  ? generateSubmissionEmailSubject(formik.values)
                  : null,
                content: submissionJOs.length
                  ? generateSubmissionEmailIntro(formik.values)
                  : null,
              },
            });
          }}
          disabled={!formik.values.clientName}
          onBlur={() => formik.setFieldTouched('positions')}
          helperText={
            !!getIn(formik.touched, 'positions') || !!formik.submitCount
              ? getIn(formik.errors, 'positions')
              : undefined
          }
          error={
            (!!getIn(formik.touched, 'positions') || !!formik.submitCount) &&
            !!getIn(formik.errors, 'positions')
          }
        />
        <AutocompleteTextfield
          label="Send to"
          options={recipientOptions}
          fullWidth
          value={formik.values.recipients}
          multiple
          onChange={(_, val) => {
            const nextVal = val
              .map((option: Person) => {
                if (
                  typeof option === 'object' &&
                  option.email?.startsWith('Add "')
                ) {
                  const newEmail = option.email.replace(/Add\s+"(.+)"/, '$1');
                  if (validateEmail(newEmail)) {
                    return {
                      email: newEmail,
                      isStandalone: option.isStandalone,
                    }; // Create a new recipient
                  }
                } else if (typeof option === 'object' && option.email) {
                  return option; // Existing recipient object
                }
                return null; // Ignore invalid options
              })
              .filter(Boolean); // Remove nulls

            formik.setValues({
              ...formik.values,
              recipients: nextVal || [],
              email: {
                ...formik.values.email,
                content: generateSubmissionEmailIntro({
                  ...formik.values,
                  recipients: nextVal || [],
                }),
              },
            });
          }}
          disabled={!formik.values.clientName}
          filterSelectedOptions
          filterOptions={(options, params) => {
            const filtered = createFilterOptions<Person>()(options, params);

            const { inputValue } = params;
            const isExisting = options.some(
              (option) =>
                typeof option === 'object' && option.email === inputValue,
            );

            if (inputValue && validateEmail(inputValue) && !isExisting) {
              filtered.push({
                email: `Add "${inputValue}"`,
                isStandalone: true,
              });
            }

            return filtered;
          }}
          getOptionLabel={(option: Person) => option.email}
          renderTags={(selectedValues, getTagProps) =>
            selectedValues.map((option, index) => (
              <Chip
                {...getTagProps({ index })}
                icon={
                  isStandaloneRecepient(option) ? (
                    <Tooltip title="Submission via email only">
                      <MailOutlineRoundedIcon
                        fontSize="small"
                        sx={{ cursor: 'pointer' }}
                      />
                    </Tooltip>
                  ) : undefined
                }
                label={option.email}
              />
            ))
          }
          isOptionEqualToValue={(option, value) => option.email === value.email}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={formik.values.notifyOnce}
              onChange={(_, val) => {
                if (val) {
                  setNotifyOnceConfirmationDialogOpen(true);
                } else {
                  formik.setFieldValue('notifyOnce', val);
                }
              }}
            />
          }
          label="Notify once"
        />
        <AutocompleteTextfield
          label="CC"
          value={formik.values.cc}
          options={ccOptions}
          fullWidth
          multiple
          freeSolo
          filterSelectedOptions
          clearOnBlur
          selectOnFocus
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => {
              const { key, ...tagProps } = getTagProps({ index });
              const isDefault =
                defaultCCOptionsRecipients.includes(option) ||
                newHomeTOMRecipient.includes(option);

              return (
                <Chip
                  key={key}
                  label={option}
                  {...tagProps}
                  disabled={isDefault}
                  onDelete={!isDefault ? tagProps.onDelete : undefined}
                />
              );
            })
          }
          filterOptions={(options, params) => {
            const filtered = createFilterOptions<string>()(options, params);
            const { inputValue } = params;
            const isExisting = options.some((option) => inputValue === option);
            if (inputValue !== '' && !isExisting) {
              filtered.push(`Add "${inputValue}"`);
            }

            return filtered;
          }}
          onChange={(e: SyntheticEvent, newValue: string[] | null) => {
            const newSkillNameValue = newValue?.map((value) =>
              value?.includes('Add "')
                ? /Add "(.*)"/gi.exec(value)?.[1]
                : value,
            );

            const finalValues = [
              ...defaultCCOptionsRecipients,
              ...(newSkillNameValue?.filter(
                (v) => v && !defaultCCOptionsRecipients.includes(v),
              ) || []),
            ];

            formik.setFieldValue('cc', finalValues);
          }}
          onBlur={() => formik.setFieldTouched('cc')}
          helperText={
            !!getIn(formik.touched, 'cc') || !!formik.submitCount
              ? getIn(formik.errors, 'cc')
              : undefined
          }
          error={
            (!!getIn(formik.touched, 'cc') || !!formik.submitCount) &&
            !!getIn(formik.errors, 'cc')
          }
        />
        <TextField
          label="Email subject"
          variant="outlined"
          fullWidth
          value={formik.values.email?.subject || ''}
          onBlur={() => formik.setFieldTouched('email.subject')}
          onChange={(e) =>
            formik.setFieldValue('email.subject', e.target.value || null)
          }
          helperText={
            !!getIn(formik.touched, 'email.subject') || !!formik.submitCount
              ? getIn(formik.errors, 'email.subject')
              : undefined
          }
          error={
            (!!getIn(formik.touched, 'email.subject') ||
              !!formik.submitCount) &&
            !!getIn(formik.errors, 'email.subject')
          }
        />
        <TextField
          label="Email intro"
          variant="outlined"
          fullWidth
          multiline
          minRows={3}
          value={formik.values.email?.content || ''}
          onChange={(e) =>
            formik.setFieldValue('email.content', e.target.value || null)
          }
          onBlur={() => formik.setFieldTouched('email.content')}
          helperText={
            !!getIn(formik.touched, 'email.content') || !!formik.submitCount
              ? getIn(formik.errors, 'email.content')
              : undefined
          }
          error={
            (!!getIn(formik.touched, 'email.content') ||
              !!formik.submitCount) &&
            !!getIn(formik.errors, 'email.content')
          }
        />
      </Box>
      <Dialog
        maxWidth="xs"
        open={isNotifyOnceConfirmationDialogOpen}
        onClose={handleConfirmationDialogClose}
      >
        <DialogTitle variant="h2">Sending single notificationn</DialogTitle>
        <DialogContent>
          <DialogContentText color="primary" variant="body1">
            Are you sure you want to notify recepients only one time and disable
            all further reminders for this submission?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <CustomButton
            color="secondary"
            label={'Cancel'}
            onClick={handleConfirmationDialogClose}
          />
          <CustomButton
            color="primary"
            label={'Notify once'}
            onClick={() => {
              formik.setFieldValue('notifyOnce', true);
              handleConfirmationDialogClose();
            }}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};
