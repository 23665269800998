import { useMemo, useState } from 'react';

import {
  Typography,
  TableContainer,
  TableRow,
  Table,
  TableCell,
  TableBody,
  Button,
  TableHead,
} from '@mui/material';
import SouthRoundedIcon from '@mui/icons-material/SouthRounded';

import {
  DEFAULT_SHOWN_WEEKS_NUMBER,
  formatDateMonthDay,
  getMondaysFromNowToDate,
  isStatusFailed,
  isStatusLoading,
  getDifferenceInDays,
  getWeekPipeline,
  getDateColumnTitleBasedOnStage,
  transformDWHCandidateToCandidatesByStage,
} from '@utils';
import { IDWHCandidate, IJobOpeningDetails } from '@types';
import { ApiStatus, CandidateStage, candidatesStageHeaders } from '@constants';
import { Flex, StageButton } from '@components/common';
import { CandidatesDetailsByStage } from './CandidatesDetailsByStage';

const getHeading = (number: number, stage: string, week: string) => {
  switch (stage) {
    case CandidateStage.APPLIED:
      return `${number} candidates applied on the week of ${week}`;
    case CandidateStage.TT_PASSED:
      return `${number} candidates passed test task on the week of ${week}`;
    case CandidateStage.IC_SCHEDULED:
      return `${number} candidates scheduled IC on the week of ${week}`;
    case CandidateStage.IC_DONE:
      return `${number} candidates did IC on the week of ${week}`;
    case CandidateStage.IC_PASSED:
      return `${number} candidates passed intro call on the week of ${week}`;
    case CandidateStage.TI_PASSED:
      return `${number} candidates passed tech interview on the week of ${week}`;
    case CandidateStage.SUBMITTED:
      return `${number} candidates submitted on the week of ${week}`;
    default:
      return 'Candidates';
  }
};

export const WeeklyPipeline: React.FC<{
  candidatesData: IDWHCandidate[];
  candidateAPIStatus: ApiStatus;
  jobOpeningData: IJobOpeningDetails | null;
  candidatesStatus: ApiStatus;
}> = ({
  candidatesData,
  candidateAPIStatus,
  jobOpeningData,
  candidatesStatus,
}) => {
  const [numWeeks, setNumWeeks] = useState(DEFAULT_SHOWN_WEEKS_NUMBER);
  const [selectedStage, setSelectedStage] = useState<{
    week: Date;
    stage: CandidateStage;
  } | null>(null);
  const dateOpened =
    jobOpeningData?.Date_Opened_Official || jobOpeningData?.Date_Pre_opened;

  const weeks = getMondaysFromNowToDate(dateOpened);
  const weeksToShow = weeks.slice(0, numWeeks);

  const isLoading = isStatusLoading(candidateAPIStatus);

  const weeksRows = useMemo(
    () =>
      weeksToShow.map((week, idx) => {
        const stages = getWeekPipeline(
          candidatesData,
          week,
          idx === weeks.length - 1,
        );
        return {
          week,
          stages,
        };
      }),
    [weeks],
  );

  const onClickPreviousWeeks = () => {
    if (numWeeks + DEFAULT_SHOWN_WEEKS_NUMBER > weeks.length) {
      setNumWeeks(weeks.length);
    } else {
      setNumWeeks(numWeeks + DEFAULT_SHOWN_WEEKS_NUMBER);
    }
  };

  const selectedCandidates = useMemo(() => {
    if (!selectedStage) return null;

    const candidates = weeksRows.find(
      ({ week }) => week.toISOString() === selectedStage.week.toISOString(),
    )?.stages[selectedStage.stage];

    return candidates?.map((e) =>
      transformDWHCandidateToCandidatesByStage(e, selectedStage.stage),
    );
  }, [selectedStage, weeksRows]);

  if (isStatusFailed(candidatesStatus)) return null;

  return (
    <Flex flexDirection="column">
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                align="left"
                sx={(theme) => ({
                  padding: '8px',
                  border: 'none',
                  background: theme.palette.highlight.neutral,
                  textWrap: 'nowrap',
                  '&:first-of-type': {
                    borderRadius: '8px 0 0 8px',
                  },
                  '&:last-of-type': {
                    borderRadius: '0 8px 8px 0',
                  },
                })}
              >
                Weekly
              </TableCell>
              {candidatesStageHeaders.map(({ label }) => (
                <TableCell
                  key={label}
                  align="left"
                  sx={(theme) => ({
                    padding: '8px',
                    borderBottom: 'none',
                    background: theme.palette.highlight.neutral,
                    textWrap: 'nowrap',
                    '&:first-of-type': {
                      borderRadius: '8px 0 0 8px',
                    },
                    '&:last-of-type': {
                      borderRadius: '0 8px 8px 0',
                    },
                  })}
                >
                  {label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading
              ? Array.from({ length: numWeeks }).map((_, idx) => (
                  <TableRow key={idx}>
                    <TableCell
                      colSpan={candidatesStageHeaders.length + 1}
                      sx={{ padding: '5px', borderBottom: 'none' }}
                      component="th"
                    >
                      ...
                    </TableCell>
                  </TableRow>
                ))
              : weeksRows.map(({ week, stages }, idx) => (
                  <TableRow key={idx}>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        padding: '8px',
                        borderBottom: 'none',
                      }}
                    >
                      {formatDateMonthDay(week)}
                    </TableCell>
                    {candidatesStageHeaders.map(({ value }) => {
                      const isActiveStage =
                        selectedStage?.stage === value &&
                        selectedStage.week.toISOString() === week.toISOString();
                      const candidatesAmount = stages[value]?.length;

                      return (
                        <TableCell
                          key={value}
                          align="left"
                          sx={{ padding: '0px', borderBottom: 'none' }}
                        >
                          <StageButton
                            amount={candidatesAmount ? candidatesAmount : '─'}
                            active={isActiveStage}
                            onClick={() =>
                              setSelectedStage(
                                isActiveStage
                                  ? null
                                  : {
                                      stage: value,
                                      week: week,
                                    },
                              )
                            }
                            variant="small"
                            disabled={!candidatesAmount}
                          />
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Flex justifyContent="space-between">
        {numWeeks < weeks.length && (
          <Button
            variant="text"
            sx={{
              justifyContent: 'end',
              color: 'text.link',
              fontWeight: 400,
              textDecoration: 'underline',
              textTransform: 'unset',
              fontSize: '0.8125rem',
              ml: -1,
            }}
            disabled={isLoading}
            onClick={onClickPreviousWeeks}
          >
            Previous weeks
            <SouthRoundedIcon fontSize="inherit" />
          </Button>
        )}
        {!isLoading && dateOpened && (
          <Typography
            variant="body2"
            sx={{ ml: 'auto', mr: 0 }}
            color="text.secondary"
          >
            {`Open for ${getDifferenceInDays(dateOpened)} days`}
          </Typography>
        )}
      </Flex>
      {!!selectedCandidates?.length && !!selectedStage && (
        <CandidatesDetailsByStage
          title={getHeading(
            selectedCandidates.length,
            selectedStage.stage,
            formatDateMonthDay(selectedStage.week),
          )}
          data={selectedCandidates}
          dateColumnTitle={getDateColumnTitleBasedOnStage(selectedStage.stage)}
          onClick={setSelectedStage}
        />
      )}
    </Flex>
  );
};
